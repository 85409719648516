import React, { FC } from "react";

//middleware
import { useSelector } from "react-redux";
import { Route } from "react-router-dom";

//interfaces
import { IStore } from "../interfaces/IStore";
import { IUser } from "../interfaces/IUser";

//views
import { Sidebar } from "../components/Sidebar/Sidebar";
import { ROUTES } from "../utils/routes";
import { Home } from "../views/Logged/Home/Home";
import { ManageProfile } from "../views/Logged/ManageProfile/ManageProfile";
import { userRoles } from "../utils/roles";
import Admin from "./admin";
import Admin_Gestion from './admin_gestion';
import Pis from "./pis";
import { News } from "../views/Logged/News/News";
import { ManageNews } from "../views/Logged/News/ManageNews/ManageNews";
import { ManagePublications } from "../views/Logged/Publications/ManagePublications/ManagePublications";
import { Publications } from "../views/Logged/Publications/Publications";
import Phds from "./phds";
import { ManageFolder } from "../views/Logged/Intranet/ManageIntranet/ManageFolder";



const LoggedRoutes: FC = () => {
  //prettier-ignore
  let { token, userRoleId, isAdmin } = useSelector(({ user }: IStore) => user as IUser);

  const isAdministration = token && userRoleId === userRoles.administration;
  const isPi = token && (userRoleId === userRoles.pi);
  const isPhd = token && (userRoleId === userRoles.phd)
  return (
    <>
      <Sidebar />
      <Route exact path="/" component={Home} />
      <Route exact path={ROUTES.ManageProfile} component={ManageProfile} />

      <Route exact path={ROUTES.Publications.all} component={Publications} />
      <Route
        exact
        path={[ROUTES.Publications.create, ROUTES.Publications.update]}
        component={ManagePublications}
      />

      {isAdmin && <Admin />}
      {isAdministration && <Admin_Gestion />}
      {isPi && <Pis />}
      {isPhd && <Phds />}
      {/* <Route
        exact
        path={[ROUTES.INTRANETFOLDERS.create, ROUTES.INTRANETFOLDERS.update]}
        component={ManageFolder}
      /> */}
    </>
  );
};

export default LoggedRoutes;

// prettier-ignore
export const validate = ({ titleEs, titleEn, titleVa, contentEs, contentVa, contentEn, keywordsEn, keywordsEs, keywordsVa, communicationTypeId, date}: any) => {
    let aux = "";
    if (!titleEs) aux = "Por favor rellene el título en Español.";
    if (!titleEn) aux = 'Por favor rellene el título en Inglès.';
    if (!titleVa) aux = 'Por favor rellene el título en Valenciano.';
    if (!contentEs) aux = "Por favor rellene el texto en Español.";
    if (!contentEn) aux = 'Por favor rellene el texto en Inglès.';
    if (!contentVa) aux = 'Por favor rellene el texto en Valenciano.';
    if (!keywordsEs) aux = "Por favor rellene las palabras clave en Español"
    if (!keywordsEn) aux = 'Por favor rellene las palabras clave en Inglès';
    if (!keywordsVa)	aux = 'Por favor rellene las palabras clave en Valenciano';

    if (!communicationTypeId) aux = "Por favor indique el tipo de noticia.";
    if (!date || date === "Invalid date") aux = "Por favor elija una fecha de publicación";

    return aux;
  };


/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';

import { IStore } from '../../../interfaces/IStore';
import { IUser } from '../../../interfaces/IUser';
import { Button, Col, Form, InputGroup, Modal } from 'react-bootstrap';
import { FileUploader } from '../../../components/FileUploader/FileUploader';
import { IInvestigator } from '../../../interfaces/IInvestigator';
import { paths } from '../../../utils/paths';
import Axios from 'axios';
import {
	create,
	Delete,
	getAll,
	getById,
	getByInvestigator,
	resetPass,
	saveFile,
	update
} from '../../../utils/requests/dynamicReq';
import { validateEmail } from '../../../utils/validate';
import { ROUTES } from '../../../utils/routes';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import { IPublication } from '../../../interfaces/IPublication';
import estrella from '../../../assets/images/estrella.svg';
import estrellaAzul from '../../../assets/images/estrellaSolid.svg';
import { requiredInputs, SwalError } from '../../../utils/constants';
import moment from 'moment';
import parse from 'html-react-parser';
import { CvaUploader } from '../../../components/FileUploader/CvaUploader';


interface IGroupShort {
	group_id: string;
	name_es: string;
}

// interface IArea {
//   area_id: string;
//   name_es: string;
// }

interface IInvestigatorRole {
	staff_role_id: string;
	name: string;
}

interface IUserRole {
	user_role_id: string;
	name: string;
}

interface INewPassword {
	oldPassword: string;
	newPassword: string;
}

interface INewUser {
	username: string;
	ownerId: number;
	userRoleId: number;
	userType: string;
	bookingsRole: string | null;
}

export const ManageProfile: FC = () => {
	let { token, staffId, isAdmin, userRoleId } = useSelector(
		({ user }: IStore) => user as IUser
	);
	const history = useHistory();

	const { pathname }: any = useLocation();
	let id: any = '';
	if (pathname.includes('perfil')) {
		id = staffId?.toString()!;
	} else {
		id = pathname.split('/')[2];
	}
	let userType: string = '';
	if (pathname.includes('service')) {
		userType = 'service-staff';
	} else {
		userType = 'investigator';
	}

	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();
	const {
		INVESTIGATORS,
		GROUPS,
		// AREAS,
		INVESTROLE,
		USERROLE,
		USERS,
		newUsers,
		userSelectPUBLICATION,
		publicationsINVESTIGATOR,
		NEWPASSWORD,
		RESETPASSWORD
	} = paths;

	const [isCreate, setIsCreate] = useState(false);
	const [isMyProfile, setIsMyProfile] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	//userForm
	const [userToEditId, setUserToEditId] = useState('');
	const [name, setName] = useState('');
	const [surname1, setSurname1] = useState('');
	const [surname2, setSurname2] = useState('');
	const [userName, setUserName] = useState('');
	const [email, setEmail] = useState('');
	const [image, setImage] = useState();
	const [phone, setPhone] = useState('');
	const [groups, setGroups] = useState<IGroupShort[]>([]);
	const [chosenGroupId, setChosenGroupId] = useState('');
	const [cva, setCva] = useState('');
	const [isCvaPublic, setIsCvaPublic] = useState('')
	//   const [areas, setAreas] = useState<IArea[]>([]);
	//   const [chosenAreaId, setChosenAreaId] = useState("");
	const [userRoles, setUserRoles] = useState<IUserRole[]>([]);
	const [staffRole, setStaffRole] = useState('');
	const [chosenUserRoleId, setChosenUserRoleId] = useState('');
	const [investigatorTypes, setInvestigatorTypes] = useState<
		IInvestigatorRole[]
	>([]);
	const [permanent, setPermanent] = useState('');
	const [contractStartDate, setContractStartDate] = useState<Date | null>(null);
	const [contractEndDate, setContractEndDate] = useState<Date | null>(null);
	const [chosenUserTypeId, setChosenUserTypeId] = useState('');
	const [homepageUrl, setHomepageUrl] = useState('');
	const [publonsId, setPublons] = useState('');
	const [orcid, setOrcid] = useState('');
	const [researchGate, setResearchGate] = useState('');
	const [blueSky, setBlueSky] = useState('');
	const [googleSchoolar, setGoogleShcoolar] = useState('');
	const [twitter, setTwitter] = useState('');
	const [professionalCategoryEs, setProfessionalCategoryEs] = useState('');
	const [professionalCategoryEn, setProfessionalCategoryEn] = useState('');
	const [professionalCategoryVa, setProfessionalCategoryVa] = useState('');

	const [superiorCategory, setSuperiorCategory] = useState('');
	const [subtype, setSubtype] = useState('ninguno');
	const [subtypeDetailsEs, setSubtypeDetailsEs] = useState('');
	const [subtypeDetailsEn, setSubtypeDetailsEn] = useState('');
	const [subtypeDetailsVa, setSubtypeDetailsVa] = useState('');
	const [linkedIn, setLinkedIn] = useState('');
	const [descriptionEs, setDescriptionEs] = useState('');
	const [descriptionEn, setDescriptionEn] = useState('');
	const [descriptionVa, setDescriptionVa] = useState('');
	const [tagsEs, setTagsEs] = useState('');
	const [tagsEn, setTagsEn] = useState('');
	const [tagsVa, setTagsVa] = useState('');
	const [password, setPassword] = useState('');
	const [repeatPassword, setRepeatPassword] = useState('');

	const [bookingsRole, setBookingsRole] = useState<string>("");
	const [selectedPublications, setSelectedPublications] = useState<
		IPublication[]
	>([]);
	const [displayPublications, setDisplayPublications] = useState<
		IPublication[]
	>([]);

	//change Password
	const [oldPassword, setOldPassword] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [repeatNewPass, setRepeatNewPass] = useState('');
	const [isPassChecked, setIsPassChecked] = useState(false);

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [passMessage, setPassMessage] = useState('');
	const [allowedEdit, setAllowedEdit] = useState(false);

	useEffect(() => {
		isMountedRef.current = true;
		checkIfCreate();
		loadInvestigationGroups();
		loadInvestigationRoles();
		// loadAreas();
		loadUserRole();
		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
	}, []);

	const checkIfCreate = () => {
		const { pathname }: any = history.location;
		if (pathname.includes('crear')) {
			setIsCreate(true);
		} else {
			loadInvestigator();
			if (pathname.includes('perfil')) {
				setIsLoading(true);
				setIsMyProfile(true);
				loadUserPublications();
			}
		}
	};

	const loadInvestigationGroups = async () => {
		try {
			let rawInvGroups = await getAll(GROUPS, source);

			if (isMountedRef.current) {
				filterGruops(rawInvGroups);
			}
		} catch (error) {
			console.log('HTTP call cancelled');
			console.log(error);
		}
	};

	const filterGruops = (rawGroups: any[]) => {
		let filteredGropus: IGroupShort[] = rawGroups.map(({ id, nameEs }) => ({
			group_id: id,
			name_es: nameEs
		}));
		setGroups(filteredGropus);
	};

	//   const loadAreas = async () => {
	//     try {
	//       let rawAreas = await getAll(AREAS, source);

	//       if (isMountedRef.current) {
	//         filterAreas(rawAreas);
	//       }
	//     } catch (error) {
	//       console.log("HTTP call cancelled");
	//     }
	//   };

	//   const filterAreas = (rawAreas: any[]) => {
	//     let filteredAreas: IArea[] = rawAreas.map(({ id, nameEs }) => ({
	//       area_id: id,
	//       name_es: nameEs,
	//     }));
	//     setAreas(filteredAreas);
	//   };

	const loadInvestigationRoles = async () => {
		try {
			let roles = await getAll(INVESTROLE, source);

			if (isMountedRef.current) {
				filterInvestigatorRole(roles);
			}
		} catch (error) {
			console.log('HTTP call cancelled');
			console.log(error);
		}
	};

	const filterInvestigatorRole = (roles: any[]) => {
		let filteredInvestigatorRoles: IInvestigatorRole[] = roles.map(
			({ id, name }) => ({
				staff_role_id: id,
				name
			})
		);
		setInvestigatorTypes(filteredInvestigatorRoles);
	};

	const loadUserPublications = async () => {
		let publications = await getByInvestigator(
			publicationsINVESTIGATOR,
			staffId,
			source
		);
		// setPublications(publications);
		reorderPublications(publications);
		setIsLoading(false);
	};

	const chechEmail = (value: string) => {
		let validatedEmail = validateEmail(value);
		if (!validatedEmail) {
			setMessage('Formato de email no válido.');
		} else {
			setMessage('');
		}
	};

	//----------------

	//HANDLE PUBLICATIONS

	const reorderPublications = (publications: any[]) => {
		let newPublications: any[] = [];
		publications.map(
			({
				id,
				title,
				date,
				year,
				journal,
				url,
				authors,
				volPag,
				staffId,
				staffPublications
			}: // eslint-disable-next-line array-callback-return
			any) => {
				newPublications.push({
					id,
					title,
					date,
					year,
					journal,
					url,
					authors,
					volPag,
					staffId,
					selected: staffPublications[0]?.selected
				});
			}
		);
		return filterSelectedPublications(newPublications);
	};

	const filterSelectedPublications = (publications: IPublication[]) => {
		const selectedPublications = publications.filter(
			({ selected }: IPublication) => selected === true
		);
		setSelectedPublications(selectedPublications);
		const displayPublications = publications.filter(
			({ selected }: IPublication) => selected === false
		);
		setDisplayPublications(displayPublications);
	};

	//----------------
	const createOREditInvestigatorForm = () => {
		let form: IInvestigator = isCreate
			? {
					name,
					surname1,
					surname2,
					email,
					phone,
					homepageUrl,
					descriptionEs,
					descriptionEn,
					descriptionVa,
					tagsEs,
					tagsEn,
					tagsVa,
					investigationGroupId: +chosenGroupId! ? +chosenGroupId! : null,
					investigatorRoleId:
						staffRole == 'investigation'
							? +chosenUserTypeId
								? +chosenUserTypeId
								: null
							: null,
					staffRole,
					permanent: permanent === '1' ? 1 : 0,

					contractStartDate: contractStartDate
						? moment(contractStartDate).format('YYYY-MM-DD')
						: null,
					contractEndDate: contractEndDate
						? moment(contractEndDate).format('YYYY-MM-DD')
						: null,
					// areaId: +chosenAreaId!,
					linkedin: linkedIn,
					twitter,
					publonsId,
					orcid,
					researchGate,
					blueSky,
					googleSchoolar,
					username: userName,
					password,
					userRoleId: +chosenUserRoleId,
					professionalCategoryEs,
					professionalCategoryEn,
					professionalCategoryVa,
					superiorCategory,
					subtype,
					subtypeDetailsEs,
					subtypeDetailsEn,
					subtypeDetailsVa,
					bookingsRole: bookingsRole ? bookingsRole : null,
					isCvaPublic: isCvaPublic === '1' ? true : false
			  }
			: {
					name,
					surname1,
					surname2,
					email,
					phone,
					homepageUrl,
					descriptionEs,
					descriptionEn,
					descriptionVa,
					tagsEs,
					tagsEn,
					tagsVa,
					investigationGroupId: +chosenGroupId! ? +chosenGroupId! : null,
					investigatorRoleId:
						staffRole == 'investigation'
							? +chosenUserTypeId
								? +chosenUserTypeId
								: null
							: null,
					staffRole,
					permanent: permanent === '1' ? 1 : 0,

					contractStartDate: contractStartDate
						? moment(contractStartDate).format('YYYY-MM-DD')
						: null,
					contractEndDate: contractEndDate
						? moment(contractEndDate).format('YYYY-MM-DD')
						: null,
					// areaId: +chosenAreaId!,
					linkedin: linkedIn,
					twitter,
					publonsId,
					orcid,
					researchGate,
					blueSky,
					googleSchoolar,
					professionalCategoryEs,
					professionalCategoryEn,
					professionalCategoryVa,
					superiorCategory,
					subtype,
					subtypeDetailsEs,
					subtypeDetailsEn,
					subtypeDetailsVa,
					bookingsRole: bookingsRole ? bookingsRole : null,
					isCvaPublic: isCvaPublic === '1' ? 1 : 0
			  };

		return form;
	};

	const editUserForm = (ownerId: number) => {
		let userForm: INewUser = {
			username: userName,
			ownerId,
			userType,
			bookingsRole: bookingsRole ? bookingsRole : null,
			userRoleId: +chosenUserRoleId
		};
		return userForm;
	};

	const handleSubmit = () => {
		let question: string = '';
		isCreate
			? (question = `¿Quieres crear un nuevo usuario?`)
			: (question = ' ¿Quieres actualizar tu perfil?');
		Swal.fire({
			icon: 'question',
			text: question,
			showCancelButton: true,
			confirmButtonText: 'Confirmar',
			cancelButtonText: 'No',
			customClass: {
				confirmButton: 'buttonSwalConfirm'
			}
		}).then(({ isConfirmed }) => {
			if (isConfirmed && isCreate) {
				handleNewUser();
			} else if (isConfirmed && !isCreate) {
				handleConfirmation();
			}
		});
	};

	const loadUserRole = async () => {
		try {
			let roles = await getAll(USERROLE, source);

			if (isMountedRef.current) {
				filterUserRole(roles);
			}
		} catch (error) {
			console.log('HTTP call cancelled');
			console.log(error);
		}
	};

	const filterUserRole = (roles: any[]) => {
		let filteredUserRoles: IUserRole[] = roles.map(({ id, name }) => ({
			user_role_id: id,
			name
		}));
		setUserRoles(filteredUserRoles);
	};

	const checkPassword = (value: string) => {
		let repeatPassword = value;
		if (password !== repeatPassword) {
			setMessage('Las contraseñas no coinciden.');
		} else {
			setMessage('');
		}
	};

	const handleNewUser = () => {
		if (
			!name ||
			!surname1 ||
			!email ||
			!userName ||
			chosenGroupId === 'none' ||
			!staffRole ||
			(staffRole === 'investigation' && !chosenUserTypeId) ||
			!subtype ||
			(staffRole === 'investigation' && !userType) ||
			!chosenUserRoleId || !professionalCategoryEn || !professionalCategoryEs || !professionalCategoryVa

		) {
			Swal.fire({
				icon: 'error',
				text: 'Los campos con * son obligatorios. Por favor, completa el formulario.',
				customClass: {
					cancelButton: 'buttonClass'
				}
			});
		} else {
			let form = createOREditInvestigatorForm();
			create(newUsers, form, source)
				.then(async (userCreated) => {
					const newlyCreatedStaffId = userCreated?.staff?.id;
					Swal.fire({
						icon: 'success',
						text: `Usuario creado correctamente.`
					});
					if (typeof image !== 'string') {
						const formData = new FormData();
						formData.append('image', image!);
						await saveFile(
							INVESTIGATORS,
							newlyCreatedStaffId,
							'image',
							formData,
							source
						);
					}
						if (typeof cva !== 'string') {
							const formData = new FormData();
							formData.append('pdf', cva!);
							await saveFile(
								INVESTIGATORS,
								newlyCreatedStaffId,
								'cva',
								formData,
								source
							);
						}
					history.push(`${ROUTES.Users.all}`);
				})
				.catch((error) => {
					console.log(error);
					Swal.fire({
						icon: 'error',
						text: 'Ha habido un error, por favor intente de nuevo más tarde. Compruebe si el email o el usuario ya existe'
					});
				});
		}
	};

	// EDIT MY PROFILE
	const loadInvestigator = async () => {
		try {
			const {
				name,
				surname1,
				surname2,
				email,
				phone,
				homepageUrl,
				descriptionEs,
				descriptionEn,
				descriptionVa,
				tagsEs,
				tagsEn,
				tagsVa,
				investigationGroup,
				investigatorRole,
				staffRole,
				permanent,
				contractStartDate,
				contractEndDate,
				// area,
				linkedin,
				twitter,
				publonsId,
				orcid,
				researchGate,
				blueSky,
				googleSchoolar,
				image,
				cva,
				isCvaPublic,
				user,
				professionalCategoryEs,
				professionalCategoryEn,
				professionalCategoryVa,
				superiorCategory,
				subtype,
				subtypeDetailsEs,
				subtypeDetailsEn,
				subtypeDetailsVa
			} = await getById(INVESTIGATORS, id, source);
			let a = await getById(INVESTIGATORS, id, source);

			if (isMountedRef.current) {

				// Only initialize contractStartDate if it comes something from DB (cause its optional).
				if(contractStartDate){
					const startDateWithOffset = new Date(contractStartDate);
					const startDateNoOffset = ( new Date( startDateWithOffset.getTime() - startDateWithOffset.getTimezoneOffset() * -60000 ))
					setContractStartDate(startDateNoOffset);	
				} 
				// Only initialize contractEndDate if it comes something from DB (cause its optional).
				if(contractEndDate){
					const endDateWithOffset = new Date(contractEndDate);
					const endDateNoOffset = ( new Date( endDateWithOffset.getTime() - endDateWithOffset.getTimezoneOffset() * -60000 ));
					setContractEndDate(endDateNoOffset);
				} 

				setName(name);
				setSurname1(surname1);
				setSurname2(surname2);
				setEmail(email);
				setPhone(phone);
				setHomepageUrl(homepageUrl);
				setDescriptionEs(descriptionEs);
				setDescriptionEn(descriptionEn);
				setDescriptionVa(descriptionVa);
				setTagsEs(tagsEs);
				setTagsEn(tagsEn);
				setTagsVa(tagsVa);
				investigationGroup?.id !== 'NULL'
					? setChosenGroupId(investigationGroup?.id)
					: setChosenGroupId('');
				setChosenUserTypeId(investigatorRole?.id);
				// setChosenAreaId(area?.id);
				setLinkedIn(linkedin);
				setTwitter(twitter);
				setPublons(publonsId);
				setOrcid(orcid);
				setResearchGate(researchGate);
				setBlueSky(blueSky);
				setGoogleShcoolar(googleSchoolar);
				setStaffRole(staffRole);
				setPermanent(permanent === true ? '1' : '0');
				setImage(image);
				setCva(cva);
				setIsCvaPublic(isCvaPublic === true ? '1' : '0')
				setUserName(user?.username);
				setUserToEditId(user?.id);
				setChosenUserRoleId(user?.userRole?.id);
				setProfessionalCategoryEs(professionalCategoryEs);
				setProfessionalCategoryEn(professionalCategoryEn);
				setProfessionalCategoryVa(professionalCategoryVa)
				setSuperiorCategory(superiorCategory);
				setSubtype(subtype);
				setSubtypeDetailsEs(subtypeDetailsEs);
				setSubtypeDetailsEn(subtypeDetailsEn);
				setSubtypeDetailsVa(subtypeDetailsVa);
				setBookingsRole(user?.bookingsRole);
			}
		} catch (error) {
			console.log('HTTP call cancelled');
			console.log(error);
		}
	};


	const handleConfirmation = async () => {
		if (
			!name ||
			!surname1 ||
			!email ||
			!userName ||
			!staffRole ||
			(staffRole === 'investigation' && !chosenUserTypeId) ||
			!subtype ||
			
			!phone ||
			!professionalCategoryEn || 
			!professionalCategoryEs || !professionalCategoryVa
		) {
			Swal.fire({
				icon: 'error',
				text: 'Los campos con * son obligatorios. Por favor, completa el formulario.',
				customClass: {
					cancelButton: 'buttonClass'
				}
			});
			console.log(name, surname1, email, userName, staffRole, chosenUserTypeId, subtype, chosenUserTypeId, phone, professionalCategoryEn, professionalCategoryEs, professionalCategoryVa);
			
		} else {
			let form = createOREditInvestigatorForm();
			update(INVESTIGATORS, id, form, token).then(() => {
				let userForm = editUserForm(+id);
				update(USERS, +userToEditId, userForm, token)
					.then(async () => {
						Swal.fire({
							icon: 'success',
							text: `Perfil actualizado correctamente.`
						});
						if (typeof image !== 'string') {
							const formData = new FormData();
							formData.append('image', image!);
							await saveFile(INVESTIGATORS, id, 'image', formData, source);
						}
						if (typeof CvaUploader !== 'string') {
							const formData = new FormData();
							formData.append('pdf', cva!);
							await saveFile(INVESTIGATORS, id, 'cva', formData, source);
						}
					})
					.catch(() => {	
						Swal.fire({
							icon: 'error',
							text: 'Ha habido un error, por favor intente de nuevo más tarde..'
						});
					});
			});
		}
	};

	const handleDelete = () => {
		Swal.fire({
			icon: 'question',
			text: `¿Quieres borrar este usuario?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(({ isConfirmed }) => {
			isConfirmed &&
				Delete(INVESTIGATORS, id, source)
					.then(({ id }) => {
						Swal.fire({
							icon: 'success',
							text: `Usuario borrado correctamente.`
						});
						history.push(ROUTES.Users.all);
						setIsCreate(false);
					})
					.catch((error) => {
						console.log(error);
						Swal.fire({
							icon: 'error',
							text: 'Ha habido un error, por favor intente de nuevo más tarde.'
						});
					});
		});
	};

	const editDeleteButtons = () => {
		if (!isCreate && !isMyProfile) {
			return (
				<>
					<button className="baseBtn" onClick={handleSubmit}>
						Actualizar Perfil
					</button>
					<button
						className="smallBtn btn-success ml-1"
						onClick={handleResetPassword}
					>
						<FontAwesomeIcon icon={faRedo} />
					</button>
					<button className="smallBtn btn-danger ml-1" onClick={handleDelete}>
						<FontAwesomeIcon icon={faTrash} />
					</button>
				</>
			);
		} else if (!isCreate && isMyProfile) {
			return (
				<>
					<button className="baseBtn" onClick={handleSubmit}>
						Actualizar Perfil
					</button>
				</>
			);
		} else {
			return (
				<button className="baseBtn" onClick={handleSubmit}>
					Crear Usuario
				</button>
			);
		}
	};

	//----------------

	//PUBLICATIONS

	const searchBar = (value: any) => {
		setDisplayPublications(
			displayPublications.filter(({ title }: any) =>
				title.toLowerCase().includes(value.toLowerCase())
			)
		);
		!value && setDisplayPublications(displayPublications);
	};

	const changeIcon = (isSelected: any) => {
		return !isSelected ? estrella : estrellaAzul;
	};

	const changeNotSelected = (index: number) => {
		if (displayPublications[index!].selected === false) {
			const newdisplayPublications = [...displayPublications];
			newdisplayPublications[index!].selected = true;
			setDisplayPublications(newdisplayPublications);
			setTimeout(() => {
				handleSubmitPublications();
			}, 200);
		} else if (displayPublications[index!].selected === true) {
			const newdisplayPublications = [...displayPublications];
			newdisplayPublications[index!].selected = false;
			setDisplayPublications(newdisplayPublications);
			setTimeout(() => {
				handleSubmitPublications();
			}, 200);
		}
	};

	const changeSelected = (index: number) => {
		if (selectedPublications[index!].selected === false) {
			const newSelectedPublications = [...selectedPublications];
			newSelectedPublications[index!].selected = true;
			setSelectedPublications(newSelectedPublications);
			setTimeout(() => {
				handleSubmitPublications();
			}, 200);
		} else if (selectedPublications[index!].selected === true) {
			const newSelectedPublications = [...selectedPublications];
			newSelectedPublications[index!].selected = false;
			setSelectedPublications(newSelectedPublications);
			setTimeout(() => {
				handleSubmitPublications();
			}, 200);
		}
	};

	//----------------
	//HANDLE SELECTED PUBLICATIONS

	const handleSubmitPublications = () => {
		const newPublications = buildSelectedPublications();
		const count = countSelected(newPublications);
		if (count > 5) {
			Swal.fire({
				icon: 'error',
				text: 'Se pueden seleccionar un máximo de 5 publicaciones',
				customClass: {
					cancelButton: 'buttonClass'
				}
			});
		} else {
			create(
				`${userSelectPUBLICATION}/${id}/publication/`,
				newPublications,
				source
			)
				.then(() => {
					Swal.fire({
						icon: 'success',
						text: `Publicaciones seleccionadas correctamente.`
					});
					loadUserPublications();
					isMountedRef.current && setIsCreate(false);
				})
				.catch(() => {
					Swal.fire({
						icon: 'error',
						text: SwalError
					});
				});
		}
	};

	const buildSelectedPublications = () => {
		let newSelectedPublications: any[] = [];
		let completePublications = [
			...selectedPublications,
			...displayPublications
		];
		completePublications.map(({ id: publicationId, selected }: any) =>
			newSelectedPublications.push({
				staffId,
				publicationId,
				selected
			})
		);
		return newSelectedPublications;
	};

	const countSelected = (totalPublications: any[]) => {
		const count = totalPublications.filter(
			({ selected }) => selected === true
		).length;
		return count;
	};

	//----------------
	//CHANGE PASSWORD

	const checkNewPassword = (value: string) => {
		let repeatNewPass = value;
		if (newPassword !== repeatNewPass) {
			setPassMessage('Las contraseñas no coinciden.');
			setIsPassChecked(false);
		} else {
			setPassMessage('');
			setIsPassChecked(true);
		}
	};

	//----------------
	//HANDLE CAHNGE PASSWORD

	const handleSubmitNewPassword = () => {
		if (!oldPassword || !newPassword || !repeatNewPass) {
			setPassMessage(requiredInputs);
			setTimeout(() => setPassMessage(''), 4000);
			return;
		}
		const newPasswordForm: INewPassword = {
			oldPassword,
			newPassword
		};
		Swal.fire({
			icon: 'question',
			text: `¿Quieres cambiar tu contraseña?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(({ isConfirmed }) => {
			isConfirmed && handleNewPasswordConfirmation(newPasswordForm);
		});
	};

	const handleNewPasswordConfirmation = (newPasswordForm: INewPassword) => {
		create(NEWPASSWORD, newPasswordForm, source)
			.then(() => {
				Swal.fire({
					icon: 'success',
					text: `Contraseña cambiada correctamente.`
				});
				isMountedRef.current && setIsCreate(false);
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					text: SwalError
				});
			});
	};

	//----------------
	//HANDLE RESET PASSWORD

	const handleResetPassword = () => {
		Swal.fire({
			icon: 'question',
			text: `¿Quieres resetear la contraseña de este usuario?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(({ isConfirmed }) => {
			isConfirmed &&
				resetPass(RESETPASSWORD, +userToEditId, source)
					.then(() => {
						const year = moment(new Date()).locale('es').format('YYYY');
						Swal.fire({
							icon: 'success',
							text: `Contraseña reseteada a i2sysbio${year}`
						});
						isMountedRef.current && setIsCreate(false);
					})
					.catch(() => {
						Swal.fire({
							icon: 'error',
							text: SwalError
						});
					});
		});
	};

	const cleanContractDates = () => {
		setContractStartDate(null);
		setContractEndDate(null);
	}
	const cleanSubtypeDetails = () =>{
		setSubtypeDetailsEs('');
		setSubtypeDetailsEn('');
		setSubtypeDetailsVa('');

	}

	return (
		<div className="centerPage">
			<div className="container-fluid ">
				<div className="row">
					<div className="col d-flex justify-content-between">
						<div>
							<h3>Perfil</h3>
						</div>
						<div>{editDeleteButtons()}</div>
					</div>
				</div>
			</div>
			<div className="container-fluid ">
				<div className="row">
					<div className="col-12 text-center text-danger">
						<span>{message}</span>
					</div>
				</div>
			</div>
			<div className="container-fluid mt-4">
				<div className="form-row mt-2  d-flex flex-start">
					{!isCreate ? (
						<div style={{ width: '50%', paddingLeft: '10%' }}>
							<FileUploader handleFile={setImage} image={image} />
						</div>
					) : (
						<div style={{ width: '50%', paddingLeft: '10%' }}>
							<FileUploader handleFile={setImage} image={image} />
						</div>
					)}

					<div className="col-6   justify-content-center">
						<small id="label">Nombre*</small>
						<input
							type="text"
							className="form-control"
							// placeholder="Nombre"
							value={name}
							onChange={({ target: { value } }) => {
								setName(value);
							}}
						/>
						<div className=" mt-2">
							{' '}
							<small>Primer apellido*</small>
							<input
								type="text"
								className="form-control"
								// placeholder="Primer Apellido"
								value={surname1}
								onChange={({ target: { value } }) => {
									setSurname1(value);
								}}
							/>
						</div>
						<div className=" mt-2">
							<small>Segundo apellido</small>
							<input
								type="text"
								className="form-control"
								// placeholder="Segundo Apellido"
								value={surname2!}
								onChange={({ target: { value } }) => {
									setSurname2(value);
								}}
							/>
						</div>
					</div>
				</div>
				<div className="form-row mt-3">
					<h5>Datos de usuario</h5>
				</div>

				<div className="form-row mt-2">
					<div className="col">
						<small>Nombre de Usuario*</small>
						<input
							type="text"
							className="form-control"
							// placeholder="Nombre de usuario"
							value={userName}
							onChange={({ target: { value } }) => {
								setUserName(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Email*</small>
						<input
							type="text"
							className="form-control"
							// placeholder="Email"
							value={email}
							onChange={({ target: { value } }) => {
								setEmail(value);
								chechEmail(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Teléfono*</small>
						<input
							type="text"
							className="form-control"
							// placeholder="Teléfono"
							value={phone ? phone : ''}
							onChange={({ target: { value } }) => {
								setPhone(value);
							}}
						/>
					</div>
				</div>

				{isCreate && (
					<div className="form-row">
						<div className="col">
							<small id="label">Contraseña*</small>
							<input
								type="password"
								className="form-control"
								// placeholder="Contraseña"
								disabled={!isCreate}
								value={password}
								onChange={({ target: { value } }) => {
									setPassword(value);
								}}
							/>
						</div>
						<div className="col">
							<small>Repetir contraseña*</small>
							<input
								type="password"
								className="form-control"
								// placeholder="Repetir contraseña"
								disabled={!isCreate}
								value={repeatPassword}
								onChange={({ target: { value } }) => {
									setRepeatPassword(value);
									checkPassword(value);
								}}
							/>
						</div>
					</div>
				)}

				{(isCreate || isAdmin) && (
					<div className="form-row mt-3">
						<div className="col">
							<small>Grupo</small>
							<select
								className="form-control input"
								value={chosenGroupId!}
								onChange={({ target: { value } }) => {
									setChosenGroupId(value);
								}}
							>
								<option value="none">Grupo</option>
								<option value="">No Procede</option>
								{groups?.map(({ group_id, name_es }) => (
									<option key={group_id} value={group_id}>
										{name_es}
									</option>
								))}
							</select>
						</div>

						<div className="col">
							<small>Categoría de usuario*</small>
							<select
								className="form-control input"
								value={staffRole}
								onChange={({ target: { value } }) => {
									setStaffRole(value);
								}}
							>
								<option value="">Categoría de usuario</option>
								<option value="administration">Administración</option>
								<option value="technical">Técnico</option>{' '}
								<option value="investigation">Investigación</option>
							</select>
						</div>

						<div className="col">
							<small>Tipo de investigador*</small>
							<select
								className="form-control input"
								value={chosenUserTypeId}
								onChange={({ target: { value } }) => {
									setChosenUserTypeId(value);
								}}
							>
								{staffRole === 'investigation' ? (
									<>
										<option value="">Tipo de Investigador</option>
										{investigatorTypes?.map(({ staff_role_id, name }) => (
											<option key={staff_role_id} value={staff_role_id}>
												{name}
											</option>
										))}
									</>
								) : staffRole === 'technical' ? (
									<>
										<option value="">No Procede</option>
									</>
								) : staffRole === 'administration' ? (
									<>
										<option value="">No Procede</option>
									</>
								) : (
									<>
										<option value="">
											Seleccione una categoría de usuario
										</option>
									</>
								)}
							</select>
						</div>
						<div className="col">
							<small>Rol de usuario*</small>
							<select
								className="form-control input"
								value={chosenUserRoleId}
								onChange={({ target: { value } }) => {
									setChosenUserRoleId(value);
								}}
							>
								<option>Rol de usuario</option>
								{userRoles?.map(({ user_role_id, name }) => (
									<option key={user_role_id} value={user_role_id}>
										{name}
									</option>
								))}
							</select>
						</div>
					</div>
				)}
				{(isCreate || isAdmin) && (
					<div className="form-row mt-3">
						
						<div className="col-3">
						<small>Rol de Reservas</small>
						<select
							className="form-control input"
							value={bookingsRole}
							onChange={({ target: { value } }) => {
								setBookingsRole(value);

							}}
						>
							<option value="">No aplica</option>
							<option value="user">Usuario</option>
							<option value="manager">Manager</option>
							<option value="admin">Admin</option>
						</select>
					</div>

						<div className="col-3">
							<small>Permanente</small>
							<select
								className="form-control input"
								value={permanent}
								onChange={({ target: { value } }) => {
									setPermanent(value);
									if (value == '1') cleanContractDates();
								}}
							>
								<option value={1}>Si</option>
								<option value={0}>No</option>
							</select>
						</div>
						{permanent == '0' && (
							<>
								<div className="col-3">
									<small>Fecha de inicio</small>
									<DatePicker
										className="form-control input"
										placeholderText="Inicio"
										selected={contractStartDate}
										onChange={(date: any) => setContractStartDate(date)}
										dateFormat="dd MMMM, yyyy"
										isClearable={allowedEdit}
										locale="es"
									/>
								</div>
								<div className="col-3">
									<small>Fecha de finalizacion</small>
									<DatePicker
										className="form-control input"
										placeholderText="Finalización"
										selected={contractEndDate}
										onChange={(date: any) => setContractEndDate(date)}
										dateFormat="dd MMMM, yyyy"
										isClearable={allowedEdit}
										locale="es"
									/>
								</div>
							</>
						)}
					</div>
				)}
				<div className="form-row mt-2">
					<div className="col">
						<small>Categoría profesional Español*</small>
						<input
							type="text"
							className="form-control"
							// placeholder="Categoría Español"
							value={professionalCategoryEs ? professionalCategoryEs : ''}
							onChange={({ target: { value } }) => {
								setProfessionalCategoryEs(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Categoría profesional Ingles*</small>
						<input
							type="text"
							className="form-control"
							// placeholder="Categoría Español"
							value={professionalCategoryEn ? professionalCategoryEn : ''}
							onChange={({ target: { value } }) => {
								setProfessionalCategoryEn(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Categoría profesional Valenciano*</small>
						<input
							type="text"
							className="form-control"
							// placeholder="Categoría Español"
							value={professionalCategoryVa ? professionalCategoryVa : ''}
							onChange={({ target: { value } }) => {
								setProfessionalCategoryVa(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Categoría superior</small>
						<input
							type="text"
							className="form-control"
							placeholder="UV, CSIC, Otro"
							value={superiorCategory ? superiorCategory : ''}
							onChange={({ target: { value } }) => {
								setSuperiorCategory(value);
							}}
						/>
					</div>
				</div>
				<div className="form-row mt-2">
					<div className="col-6">
						<small>Subtipo</small>
						<select
							className="form-control input"
							value={subtype}
							onChange={({ target: { value } }) => {
								setSubtype(value);

								if (value == 'ninguno') cleanSubtypeDetails();
							}}
						>
							<option value="ninguno">No aplica</option>
							<option value="direccion">Dirección</option>
							<option value="empresas">Empresas</option>
							<option value="direccion_empresas">Dirección, Empresas</option>
							<option value="gerente">Gerente</option>
							<option value="investigador_invitado">
								Investigador invitado
							</option>
							<option value="unidad_asociada">Unidad asociada</option>
							<option value="otros">Otros</option>
						</select>
					</div>

					{subtype !== 'ninguno' && (
						<div className="col-6">
							<small>Detalle subtipo Español</small>
							<input
								type="text"
								className="form-control"
								// placeholder="Categoría Inglés"
								value={subtypeDetailsEs}
								onChange={({ target: { value } }) => {
									setSubtypeDetailsEs(value);
								}}
							/>
						</div>
					)}
					{subtype !== 'ninguno' && (
						<div className="col-6">
							<small>Detalle subtipo Ingles</small>
							<input
								type="text"
								className="form-control"
								// placeholder="Categoría Inglés"
								value={subtypeDetailsEn}
								onChange={({ target: { value } }) => {
									setSubtypeDetailsEn(value);
								}}
							/>
						</div>
					)}
					{subtype !== 'ninguno' && (
						<div className="col-6">
							<small>Detalle subtipo Valenciano</small>
							<input
								type="text"
								className="form-control"
								// placeholder="Categoría Inglés"
								value={subtypeDetailsVa}
								onChange={({ target: { value } }) => {
									setSubtypeDetailsVa(value);
								}}
							/>
						</div>
					)}
					{!isCreate ? (
						<div className=" col">
							<span>Cva </span>
							<CvaUploader handleFile={setCva} cva={cva} />
						</div>
					) : (
						<div className="col">
							<span>Cva </span>
							<CvaUploader handleFile={setCva} cva={cva} />
						</div>
					)}
					<div className="col">
						<span>Cva público?</span>
						<select
							className="form-control input"
							value={isCvaPublic}
							onChange={({ target: { value } }) => {
								setIsCvaPublic(value);
							}}
						>
							<option value={0}>Cva oculto</option>
							<option value={1}>Cva público</option>
						</select>
					</div>
				</div>

				<div className="form-row mt-3">
					<h5>Enlaces personales</h5>
				</div>
				<div className="form-row mt-4">
					<div className="col">
						<small>Enlace a página personal</small>
						<input
							type="text"
							className="form-control"
							placeholder="Enlace a página personal Ejemplo: https://www.mipaginapersonal.es"
							value={homepageUrl ? homepageUrl : ''}
							onChange={({ target: { value } }) => {
								setHomepageUrl(value);
							}}
						/>
					</div>
				</div>
				<div className="form-row mt-3"></div>
				<div className="form-row mt-3">
					<div className="col">
						<small>LinkedIn</small>
						<input
							type="text"
							className="form-control"
							placeholder="Ej:https://www.linkedin.com/school/universidad-de-malaga/?originalSubdomain=es"
							value={linkedIn ? linkedIn : ''}
							onChange={({ target: { value } }) => {
								setLinkedIn(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Twitter</small>
						<input
							type="text"
							className="form-control"
							placeholder="Ej:https://twitter.com/sfi_UMA"
							value={twitter ? twitter : ''}
							onChange={({ target: { value } }) => {
								setTwitter(value);
							}}
						/>
					</div>
				</div>
				<div className="form-row mt-3">
					<div className="col">
						<small>Publons id</small>
						<input
							type="text"
							className="form-control"
							placeholder="Ej:https://www.linkedin.com/school/universidad-de-malaga/?originalSubdomain=es"
							value={publonsId ? publonsId : ''}
							onChange={({ target: { value } }) => {
								setPublons(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Orcid</small>
						<input
							type="text"
							className="form-control"
							placeholder="Ej:https://twitter.com/sfi_UMA"
							value={orcid ? orcid : ''}
							onChange={({ target: { value } }) => {
								setOrcid(value);
							}}
						/>
					</div>
				</div>
				<div className="form-row mt-3">
					<div className="col">
						<small>Research gate</small>
						<input
							type="text"
							className="form-control"
							placeholder="Ej:https://www.linkedin.com/school/universidad-de-malaga/?originalSubdomain=es"
							value={researchGate ? researchGate : ''}
							onChange={({ target: { value } }) => {
								setResearchGate(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Blue sky</small>
						<input
							type="text"
							className="form-control"
							placeholder="Ej:https://twitter.com/sfi_UMA"
							value={blueSky ? blueSky : ''}
							onChange={({ target: { value } }) => {
								setBlueSky(value);
							}}
						/>
					</div>
				</div>
				<div className="form-row mt-3">
					<div className="col">
						<small>Google Schoolar</small>
						<input
							type="text"
							className="form-control"
							placeholder="Ej:https://www.linkedin.com/school/universidad-de-malaga/?originalSubdomain=es"
							value={googleSchoolar ? googleSchoolar : ''}
							onChange={({ target: { value } }) => {
								setGoogleShcoolar(value);
							}}
						/>
					</div>
					<div className="col"></div>
				</div>
				<div className="form-row mt-3">
					<h5>Biografía</h5>
				</div>
				<div className="form-row mt-2">
					<div className="col">
						<small>Biografía Español</small>
						<textarea
							className="form-control textarea"
							// placeholder="Resumen Español"
							rows={3}
							value={descriptionEs ? descriptionEs : ''}
							onChange={({ target: { value } }) => {
								setDescriptionEs(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Biografía Inglés</small>
						<textarea
							className="form-control textarea"
							// placeholder="Resumen Inglés"
							rows={3}
							value={descriptionEn ? descriptionEn : ''}
							onChange={({ target: { value } }) => {
								setDescriptionEn(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Biografía Valenciano</small>
						<textarea
							className="form-control textarea"
							// placeholder="Resumen Español"
							rows={3}
							value={descriptionVa ? descriptionVa : ''}
							onChange={({ target: { value } }) => {
								setDescriptionVa(value);
							}}
						/>
					</div>
				</div>
				<div className="form-row mt-3">
					<div className="col">
						<small>Palabras clave español (separado por comas)</small>
						<textarea
							className="form-control textarea"
							// placeholder="Palabras clave español (separado por comas)"
							rows={3}
							value={tagsEs ? tagsEs : ''}
							onChange={({ target: { value } }) => {
								setTagsEs(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Palabras clave inglés (separado por comas)</small>
						<textarea
							className="form-control textarea"
							// placeholder="Palabras clave inglés (separado por comas)"
							rows={3}
							value={tagsEn ? tagsEn : ''}
							onChange={({ target: { value } }) => {
								setTagsEn(value);
							}}
						/>
					</div>
					<div className="col">
						<small>Palabras clave Valenciano (separado por comas)</small>
						<textarea
							className="form-control textarea"
							// placeholder="Palabras clave español (separado por comas)"
							rows={3}
							value={tagsVa ? tagsVa : ''}
							onChange={({ target: { value } }) => {
								setTagsVa(value);
							}}
						/>
					</div>
				</div>

				<div className="form-row mt-4">
					{isMyProfile && (
						<div className="col">
							<button className="baseBtn" onClick={() => setIsModalOpen(true)}>
								Cambiar Contraseña
							</button>
						</div>
					)}
				</div>
			</div>

			{!isCreate && isMyProfile && (
				<div className="row mt-4">
					<div className="col-12 ">
						<div className="col d-flex justify-content-between">
							<h5>Publicaciones seleccionadas</h5>
						</div>
						<table className="table table-hover mt-4">
							<tbody>
								{selectedPublications?.map(
									(
										{ id, title, authors, year, selected }: IPublication,
										index
									) => (
										<tr key={index} className="pointer">
											<td>
												<div className="d-flex justify-content-between">
													<h6>{parse(title)}</h6>
													<img
														style={{ width: '2%' }}
														src={changeIcon(selected)}
														alt=""
														onClick={() => changeSelected(index)}
													/>
												</div>

												<p>
													{authors} {','}{' '}
													{year}
												</p>
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
						{isLoading && isMyProfile && (
							<div className="d-flex justify-content-center">
								<div className="spinner-border mt-5" role="status" />
							</div>
						)}

						{!selectedPublications.length && !isLoading && (
							<div className="d-flex justify-content-center mb-3">
								<h5>-No hay publicaciones seleccionadas-</h5>
							</div>
						)}
					</div>
				</div>
			)}
			{!isCreate && isMyProfile && (
				<div className="row mt-4">
					<Form.Group as={Col}>
						<InputGroup className="shadow">
							<InputGroup.Prepend>
								<InputGroup.Text className="inputGroup ">
									<FontAwesomeIcon color="#0073B5" icon={faSearch} />
								</InputGroup.Text>
							</InputGroup.Prepend>
							<Form.Control
								type="text"
								placeholder="Buscar..."
								className="form-control"
								onChange={({ target: { value } }) => searchBar(value)}
							/>
						</InputGroup>
					</Form.Group>
				</div>
			)}
			{!isCreate && isMyProfile && (
				<div className="row mt-3">
					<div className="col-12 ">
						<h5>Publicaciones</h5>
						<table className="table table-hover">
							<tbody>
								{displayPublications?.map(
									(
										{ id, title, authors, year, selected }: IPublication,
										index
									) => (
										<tr key={index} className="pointer">
											<td>
												<div className="d-flex justify-content-between">
													<h6>{parse(title)}</h6>
													<img
														style={{ width: '2%' }}
														src={changeIcon(selected)}
														alt=""
														onClick={() => changeNotSelected(index)}
													/>
												</div>
												<p>
													{authors} {','}{' '}
													{year}
												</p>
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
						{isLoading && (
							<div className="d-flex justify-content-center">
								<div className="spinner-border mt-5" role="status" />
							</div>
						)}

						{!displayPublications.length && !isLoading && (
							<div className="d-flex justify-content-center mb-3">
								<h5>-No hay publicaciones-</h5>
							</div>
						)}
					</div>
				</div>
			)}

			{/* //Modal para cambiar contraseña */}
			<Modal centered show={isModalOpen} onHide={() => setIsModalOpen(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Actualiza tu contraseña</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="container">
						<div className="row">
							<div className="col-12 text-center text-danger">
								<span>{passMessage}</span>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<input
									className="form-control"
									type="password"
									placeholder="*Contraseña Actual"
									value={oldPassword}
									onChange={({ target: { value } }) => {
										setOldPassword(value);
									}}
								/>
							</div>
						</div>
						<div className="row mt-2">
							<div className="col">
								<input
									className="form-control"
									type="password"
									placeholder="*Nueva Contraseña"
									value={newPassword}
									onChange={({ target: { value } }) => {
										setNewPassword(value);
									}}
								/>
							</div>
						</div>
						<div className="row mt-2">
							<div className="col">
								<input
									className="form-control"
									type="password"
									placeholder="*Repetir Contraseña"
									value={repeatNewPass}
									onChange={({ target: { value } }) => {
										setRepeatNewPass(value);
										checkNewPassword(value);
									}}
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => setIsModalOpen(false)}>
						Cerrar
					</Button>
					<Button
						variant="primary"
						disabled={!isPassChecked}
						onClick={handleSubmitNewPassword}
					>
						Guardar Cambio
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
};

// prettier-ignore
export const validate = ({ textEs, textEn, textVa}: any) => {
    let aux = "";
    if (!textEs) aux = 'Por favor rellene el texto en Español';
     if (!textEn) aux = 'Por favor rellene el texto en Inglès';
      if (!textVa) aux = 'Por favor rellene el texto en Valenciano';
   
   

    return aux;
  };


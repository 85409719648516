import { TAction } from "../types";
import produce from "immer";
import { IUser } from "../../interfaces/IUser";

const initialState: IUser = {
  token: "",
  userId: 0,
  staffId: 0,
  userRoleId: 0,
  investigatorRoleId: 0,
  isAdmin: false,
  name: "",
  surname1: "",
  surname2: "",
  username: "",
  email: "",
  phone: "",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state: IUser = initialState, action: TAction): IUser =>
  produce(state, () => {
    switch (action.type) {
      case "SET_USER":
        return action.payload;
      case "UNSET_USER":
        return initialState;
      case "LOGOUT":
        return initialState;
      default:
        return state;
    }
  });

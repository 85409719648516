import React, { useState } from 'react';

//css
import './Sidebar.css';

//utils
import { LogoutAction } from '../../redux/actions';
import { ROUTES } from '../../utils/routes';

import logoi2 from '../../assets/images/white_logotipo_i2sysbio_2017-6.png';

//middleware
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import {
	faUser,
	faUsers,
	faCoffee,
	faHome,
	faSignOutAlt,
	faBriefcase,
	faBookOpen,
	faNewspaper,
	faPaintBrush,
	faCalendar,
	faBook,
	faPaperclip,
	faHandPaper,
	faChalkboardTeacher,
	faCloud,
	faSlidersH,
	faFileContract,
	faVolumeUp
	
} from '@fortawesome/free-solid-svg-icons';
import { IStore } from '../../interfaces/IStore';
import { IUser } from '../../interfaces/IUser';
import { userRoles } from '../../utils/roles';

export const Sidebar = () => {
	//prettier-ignore
	let { isAdmin, userRoleId } = useSelector(({ user }: IStore) => user as IUser);


	

	//MOCK DATA

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const history = useHistory();

	const logout = () => {
		setLoading(true);
		dispatch(LogoutAction());
		localStorage.removeItem('token');
		history.push('/');
	};

	//prettier-ignore


	

	return (
		<>
			<div className="border-right" id="sidebar-wrapper">
				<div className="sidebar-heading">
					<img className="loginLogo" src={logoi2} alt="" width={200} />
				</div>

				<Nav
					className="list-group list-group-flush"
					onSelect={(selectedKey: any) => alert(`selected ${selectedKey}`)}
				>
					<div className="sidebar-sticky"></div>
					<Nav.Item className="list-group list-group-flush">
						<NavLink
							className="list-group-item list-group-item-action inactive rem"
							activeClassName="inactive"
							to={'/'}
						>
							<FontAwesomeIcon className="iconClass mr-3" icon={faHome} />
							<span>Inicio</span>
						</NavLink>
					</Nav.Item>
					<Nav.Item className="list-group list-group-flush">
						<NavLink
							className="list-group-item list-group-item-action inactive rem"
							activeClassName="active"
							to={`${ROUTES.ManageProfile}`}
						>
							<FontAwesomeIcon className="iconClass mr-3" icon={faUser} />
							<span>Perfil</span>
						</NavLink>
					</Nav.Item>

					 <Nav.Item className="list-group list-group-flush">
						<NavLink
							className="list-group-item list-group-item-action inactive rem"
							activeClassName="active"
							to={ROUTES.Publications.all}
						>
							<FontAwesomeIcon className="iconClass mr-3" icon={faBookOpen} />
							<span>Mis publicaciones</span>
						</NavLink>
					</Nav.Item> 

					{(isAdmin || userRoleId === 2 ) && (
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Users.all}
							>
								<FontAwesomeIcon className="iconClass mr-3" icon={faUsers} />
								<span>Usuarios</span>
							</NavLink>
						</Nav.Item>
					)}

					{(isAdmin || userRoleId === 3 ) && (
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Groups.all}
							>
								<FontAwesomeIcon className="iconClass mr-3" icon={faCoffee} />
								<span>Grupos de investigación</span>
							</NavLink>
						</Nav.Item>
					)}
					{/* {isAdmin && (
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Commisions.all}
							>
								<FontAwesomeIcon className="iconClass mr-3" icon={faCoffee} />
								<span className="list-group-item list-group-item-action inactive rem">Comisiones</span>
							</NavLink>
						</Nav.Item>
					)} */}
					{(isAdmin) && (
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Lines.all}
							>
								<FontAwesomeIcon
									className="iconClass mr-3"
									icon={faHandPaper}
								/>
								<span>Acciones Transversales</span>
							</NavLink>
						</Nav.Item>
					)}

					{(isAdmin || userRoleId === 2 || userRoleId === 3) && (
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Projects.all}
							>
								<FontAwesomeIcon
									className="iconClass mr-3"
									icon={faBriefcase}
								/>
								<span>Proyectos</span>
							</NavLink>
						</Nav.Item>
					)}
					{(isAdmin || userRoleId === 2) && (						
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.News.all}
								>
								<FontAwesomeIcon
									className="iconClass mr-3"
									icon={faNewspaper}
									/>
								<span>Comunicaciones</span>
							</NavLink>
						</Nav.Item>
									)}
					

					
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Commissions.all}
							>
								<FontAwesomeIcon className="iconClass mr-3" icon={faBook} />
								<span>Comisiones</span>
							</NavLink>
						</Nav.Item>
					
					<>
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Announcements.all}
							>
								<FontAwesomeIcon
									className="iconClass mr-3"
									icon={faVolumeUp}
								/>
								<span>Anuncios de comisiones</span>
							</NavLink>
						</Nav.Item>
					</>

					{(isAdmin || userRoleId === 2) && (
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Patents.all}
							>
								<FontAwesomeIcon
									className="iconClass mr-3"
									icon={faChalkboardTeacher}
								/>
								<span>Patentes</span>
							</NavLink>
						</Nav.Item>
					)}

					{(isAdmin || userRoleId === 2 || userRoleId === 3) && (
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Offers.all}
							>
								<FontAwesomeIcon
									className="iconClass mr-3"
									icon={faFileContract}
								/>
								<span>Ofertas</span>
							</NavLink>
						</Nav.Item>
					)}
					{(isAdmin || userRoleId === 2) && (
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Sliders.all}
							>
								<FontAwesomeIcon
									className="iconClass mr-3"
									icon={faSlidersH}
								/>
								<span>Sliders</span>
							</NavLink>
						</Nav.Item>
					)}
					{(isAdmin || userRoleId === 1 || userRoleId === 3) && (
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Resource.all}
							>
								<FontAwesomeIcon
									className="iconClass mr-3"
									icon={faCloud}
								/>
								<span>Recursos</span>
							</NavLink>
						</Nav.Item>
					)}
							
						<Nav.Item className="list-group list-group-flush">
							<NavLink
								className="list-group-item list-group-item-action inactive rem"
								activeClassName="active"
								to={ROUTES.Intranet.all}
							>
								<FontAwesomeIcon
									className="iconClass mr-3"
									icon={faCloud}
								/>
								<span>Intranet</span>
							</NavLink>
						</Nav.Item>
					
				</Nav>

				<div className="logoutButton " onClick={logout}>
					<FontAwesomeIcon className="iconClass mr-3" icon={faSignOutAlt} />
					{!loading ? (
						<span>Cerrar sesión</span>
					) : (
						<span
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						/>
					)}
				</div>
			</div>
		</>
	);
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import Axios from "axios";
import { getAll, getById } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";

interface IGroupShort {
  id: string;
  nameEs: string;
}

export const Groups: FC = () => {
  const history = useHistory();

  let { staffId, isAdmin, userRoleId } = useSelector(({ user }: IStore) => user as IUser);
  console.log(isAdmin, userRoleId);
  

  const [isLoading, setIsLoading] = useState(false);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const { GROUPS, INVESTIGATORS } = paths;
  const [groups, setGroups] = useState<any>([]);
  const [search, setSearch] = useState("");
  const [filteredGroups, setFilteredGroups] = useState<IGroupShort[]>([]);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    try {
      isMountedRef.current && loadGroups();
    } catch (error) {
      console.log(error);
      console.log("HTTP call cancelled");
    }
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadGroups = async () => {
    try {
      if (isAdmin ) {
        const response = await getAll(GROUPS, source);
        if (isMountedRef.current) {
          filterGroups(response);
         
        }
      } else if (!isAdmin ) {
        let id = staffId.toString();
        const investigator = await getById(INVESTIGATORS, id, source);
        if (isMountedRef.current) {
        filterGroupByInvestigator(investigator);
        } 
      }
      setIsLoading(false);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const filterGroups = (rawGroups: any[]) => {
    let groups: IGroupShort[] = rawGroups.map(
      ({ id, nameEs }: IGroupShort) => ({
        id,
        nameEs,
      })
    );
    setGroups(groups);
    setFilteredGroups(groups);
  };

  const filterGroupByInvestigator = (rawGroups: any) => {
    // console.log(rawGroups.investigationGroup);
    if(!!rawGroups.investigationGroup){
      let groups: IGroupShort[] = [
        {
          id: rawGroups?.investigationGroup?.id,
          nameEs: rawGroups?.investigationGroup?.nameEs,
        },
      ];
      setGroups(groups);
      setFilteredGroups(groups);
    }
  };

  const handleSearch = (value: any) => {
    setSearch(value);
    setFilteredGroups(
      groups.filter(({ nameEs }: any) =>
        nameEs.toLowerCase().includes(value.toLowerCase())
      )
    );
    !value && setFilteredGroups(groups);
  };

  return (
		<div className="container-fluid p-4">
			{!isAdmin && userRoleId !== 3 ? (
				<>Usted no tiene acceso</>
			) : (
				<>
					{' '}
					<div className="row">
						<div className="col-12 d-flex justify-content-between">
							<h3>Grupos de Investigación</h3>
							<div>
								{(isAdmin || userRoleId === 3)   && (
									<button
										className="baseBtn"
										onClick={() => history.push(`${ROUTES.Groups.create}`)}
									>
										+ Añadir
									</button>
								)}
							</div>
						</div>
					</div>
					{/* <div className="center flex-column justify-content-around text-center p-5 mt-2"> */}
					<div className="row mt-4">
						<Form.Group as={Col}>
							<InputGroup className="shadow">
								<InputGroup.Prepend>
									<InputGroup.Text className="inputGroup ">
										<FontAwesomeIcon color="#0073B5" icon={faSearch} />
									</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									type="text"
									placeholder="Buscar..."
									className="form-control"
									value={search}
									onChange={(e) => handleSearch(e.target.value)}
								/>
							</InputGroup>
						</Form.Group>
					</div>
					<div className="row">
						<div className="col-12 ">
							<table className="table table-hover">
								<tbody>
									{filteredGroups?.map(
										({ id, nameEs }: IGroupShort, index: number) => (
											<tr
												key={index}
												className="pointer"
												onClick={() =>
													history.push(`${ROUTES.Groups.all}/${id}`)
												}
											>
												<td>{`${nameEs}`}</td>
											</tr>
										)
									)}
								</tbody>
							</table>
							{isLoading && (
								<div className="d-flex justify-content-center">
									<div className="spinner-border mt-5" role="status" />
								</div>
							)}

							{!filteredGroups.length && !isLoading && isAdmin && (
								<div className="d-flex justify-content-center mb-3">
									<h5>-No existen grupos-</h5>
								</div>
							)}

							{!filteredGroups.length && !isLoading && !isAdmin && (
								<div className="d-flex justify-content-center mb-3">
									<h5>-No pertenece a ningún grupo-</h5>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
		// </div>
	);
};

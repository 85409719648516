import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Axios from "axios";
import Swal from "sweetalert2";
import {
  create,
  getAll,
  getById,
  update,
} from "../../../../utils/requests/dynamicReq";
import { ROUTES } from "../../../../utils/routes";
import { paths } from "../../../../utils/paths";
import { useDelete } from "../../../../utils/hooks/useDelete";
import { SwalError } from "../../../../utils/constants";

export const ManageResourceFolder = () => {
  const { RESOURCEFOLDER } = paths;
  const { id } = useParams();
  const { deleteIt } = useDelete();
  const history = useHistory();

  const [isCreate, setIsCreate] = useState(false);
  const [name, setName] = useState("");
  const [parentId, setParentId] = useState(null);
  const [message, setMessage] = useState("");
  const [folders, setAllFolders] = useState([]);
  console.log(folders);

  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();

  useEffect(() => {
    isMountedRef.current = true;
    const { pathname } = history.location;
    pathname.includes("crear") ? setIsCreate(true) : loadData();
    loadAllFolders();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      let response = await getById(RESOURCEFOLDER, id, source);
      isMountedRef.current && buildObjToLoad(response);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

  const loadAllFolders = async () => {
    try {
      let response = await getAll(RESOURCEFOLDER, id, source);
      isMountedRef.current && setAllFolders(response);
    } catch (error) {}
  };

  const buildObjToLoad = (response) => {
    const { name, parentFolder } = response;
    setName(name);
    setParentId(parentFolder?.id || null);
    setIsCreate(false);
  };

  const handleSubmit = () => {
    const folder = { name, parentFolderId: parentId };
    Swal.fire({
      icon: "question",
      text: `¿Quieres ${isCreate ? "crear una" : "editar esta"} carpeta?`,
      showCancelButton: true,
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(({ isConfirmed }) => {
      isConfirmed && handleConfirmation(folder);
    });
  };

  const handleConfirmation = (folder) => {
    let createOrUpdate = isCreate
      ? create(RESOURCEFOLDER, folder, source)
      : update(RESOURCEFOLDER, id, folder, source);
    createOrUpdate
      .then(() => {
        Swal.fire({
          icon: "success",
          text: `Carpeta ${isCreate ? "creada" : "editada"} correctamente.`,
        });
        history.push(`${ROUTES.Resource.all}`);
        setIsCreate(false);
      })
      .catch(() => {
        Swal.fire({
          icon: "error",
          text: SwalError,
        });
      });
  };

  const editDeleteButtons = () => {
    return isCreate ? (
      <button className="baseBtn" onClick={handleSubmit}>
        Crear Carpeta
      </button>
    ) : (
      <>
        <button className="baseBtn" onClick={handleSubmit}>
          Guardar Cambios
        </button>
        <button
          className="smallBtn btn-danger ml-1"
          onClick={() =>
            deleteIt(RESOURCEFOLDER, id, ROUTES.Resource.all, source)
          }
        >
          Eliminar
        </button>
      </>
    );
  };

  return (
    <div className="centerPage">
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>{isCreate ? "Crear Carpeta" : "Editar Carpeta"}</h3>
            </div>
            <div>{editDeleteButtons()}</div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 text-center text-danger">
            <span>{message}</span>
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col-6">
            <label>Nombre de la Carpeta</label>
            <input
              type="text"
              className="form-control"
              value={name}
              onChange={(e) => setName(e.target.value)}
              maxLength={20}
            />
            <p>*Max 20 caracteres</p>
          </div>
        </div>

        <div className="form-row mt-4">
          <div className="col-6">
            <label>Carpeta contenedora</label>
            <select
              className="form-control input"
              value={parentId}
              onChange={(e) => setParentId(Number(e.target.value))}
            >
              <option value="">Seleccione una carpeta contenedora</option>
              {folders.map((f) => (
                <option value={f.id} key={f.id}>
                  {f.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

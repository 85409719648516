//middleware
import { Route } from "react-router-dom";

//views
import { ROUTES } from "../utils/routes";
import { ManageProfile } from "../views/Logged/ManageProfile/ManageProfile";
import { Users } from "../views/Logged/Users/Users";
import { ManageSubject } from "../views/Logged/Subjects/ManageSubjects/ManageSubject";
import { Subjects } from "../views/Logged/Subjects/Subjects";
import { Commissions } from "../views/Logged/Commissions/Commissions";
import { ManageCommission } from "../views/Logged/Commissions/ManageCommission/ManageCommission";
import { Offers } from "../views/Logged/Offers/Offers";
import { ManageOffer } from "../views/Logged/Offers/ManageOffer/ManageOffer";
import { Announcements } from "../views/Logged/Announcements/Announcements";
import { ManageAnnouncements } from "../views/Logged/Announcements/ManageAnnouncements/ManageAnnouncements";
import { Resources } from "../views/Logged/Resources/Resources";
import { ManageResource } from "../views/Logged/Resources/ManageResources/ManageResource";
import { ManageLines } from "../views/Logged/ResearchLines/ManageLines/ManageLines";
import { ResearchLines } from "../views/Logged/ResearchLines/ResearchLines";
import { Projects } from "../views/Logged/Projects/Projects";
import { ManageProject } from "../views/Logged/Projects/ManageProjects/ManageProject";
import { Groups } from "../views/Logged/Groups/Groups";
import { ManageGroups } from "../views/Logged/Groups/ManageGroups/ManageGroups";
import { ManageFolder } from "../views/Logged/Intranet/ManageIntranet/ManageFolder";
import { Intranets } from "../views/Logged/Intranet/Intranet";

const Pis = () => {
  // prettier-ignore
  return (
    <>
      <Route exact path={ROUTES.Lines.all} component={ResearchLines} />
      <Route
        exact
        path={[ROUTES.Lines.create, ROUTES.Lines.update]}
        component={ManageLines}
      />
      <Route exact path={ROUTES.Commissions.all} component={Commissions} />
      <Route
        exact
        path={[ROUTES.Commissions.create, ROUTES.Commissions.update]}
        component={ManageCommission}
      />

      <Route exact path={ROUTES.Offers.all} component={Offers} />
      <Route
        exact
        path={[ROUTES.Offers.create, ROUTES.Offers.update]}
        component={ManageOffer}
      />
      <Route exact path={ROUTES.Announcements.all} component={Announcements} />
      <Route
        exact
        path={[ROUTES.Announcements.create, ROUTES.Announcements.update]}
        component={ManageAnnouncements}
      />

      <Route exact path={ROUTES.Resource.all} component={Resources} />
      <Route
        exact
        path={[ROUTES.Resource.create, ROUTES.Resource.update]}
        component={ManageResource}
      />
      <Route exact path={ROUTES.Projects.all} component={Projects} />
      <Route
        exact
        path={[ROUTES.Projects.create, ROUTES.Projects.update]}
        component={ManageProject}
      />
      <Route exact path={ROUTES.Groups.all} component={Groups} />
      <Route
        exact
        path={[ROUTES.Groups.create, ROUTES.Groups.update]}
        component={ManageGroups}
      />
      <Route exact path={ROUTES.Intranet.all} component={Intranets} /> 
      <Route
        exact
        path={[ROUTES.INTRANETFOLDERS.create, ROUTES.INTRANETFOLDERS.update]}
        component={ManageFolder}
      />
    </>
  );
};

export default Pis;

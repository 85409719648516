// prettier-ignore
export const validate = ({ titleEs, titleEn, titleVa, contentEn, contentVa, contentEs, offerType, publicationDate}: any) => {
    let aux = "";
    if (!titleEs) aux = "Por favor rellene el título en Español.";
     if (!titleEn) aux = 'Por favor rellene el título en Inglès.';
      if (!titleVa) aux = 'Por favor rellene el título Valenciano.';
    if (!contentEs) aux = "Por favor rellene el texto en Español.";
    if (!contentEn) aux = 'Por favor rellene el texto en Inglés.';
    if (!contentVa) aux = 'Por favor rellene el texto en Valenciano.';
    if (!offerType) aux = "Por favor indique el tipo de oferta.";
    if (!publicationDate) aux = "Por favor rellene la fecha de publicación.";


    return aux;
  };


// prettier-ignore
export const validate = ({ nameEs, nameEn, nameVa, descriptionEs, descriptionEn, descriptionVa, keywordsEn, keywordsEs, keywordsVa}: any) => {
    let aux = "";
    if (!nameEs) aux = "Por favor rellene el nombre en español.";
    if (!nameEn) aux = 'Por favor rellene el nombre en ingles.';
    if (!nameVa) aux = 'Por favor rellene el nombre en valenciano.';
    if (!descriptionEs) aux = "Por favor rellene la descripción en español.";
    if (!descriptionEn) aux = 'Por favor rellene la descripción en ingles.';
    if (!descriptionVa) aux = 'Por favor rellene la descripción en valenciano.';
    if (!keywordsEs) aux = 'Por favor rellene las palabras clave en español.';
		if (!keywordsEn) aux = 'Por favor rellene las palabras clave en ingles.';
		if (!keywordsVa)	aux = 'Por favor rellene las palabras clave en valenciano.';

    
 

    return aux;
  };


import { FC } from 'react';
import logo from '../../../assets/images/logo.png';

export const Home: FC = () => {
	return (
		<div className="centerPage">
			<div className="center flex-column justify-content-around text-center p-5 mt-5">
				<img style={{ width: '13vw' }} src={logo} alt="" />
				<h5 className="text-secondary mt-4">
					Te damos la bienvenida a I2SysBio
				</h5>
			</div>
		</div>
	);
};

export const userRoles = {
  admin: 1,
  administration: 2,
  pi: 3,
  phd : 4
};

export const investigatorRoles = {
  ip: 1,
  pdi: 2,
  postdocsYPif: 3,
  administracion: 4,
};

import Axios from "axios";
import { store } from "../..";
import { baseURL } from "../constants";

export const login = (username: string, password: string) => {
  return Axios.post(`${baseURL}auth/login`, { username, password })
    .then(({ status, data }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(data);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getAll = (path: string, source: any) => {
  return Axios.get(`${baseURL}${path}`, {
    cancelToken: source.token,
  })
    .then(({ data: { result }, status }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getById = (path: string, id: any, source: any) => {
  return Axios.get(`${baseURL}${path}/${id}`, {
    cancelToken: source.token,
  })
    .then(({ data: { result }, status }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getByInvestigator = (path: string, id: number, source: any) => {
  return Axios.get(`${baseURL}${path}/${id}`, {
    cancelToken: source.token,
  })
    .then(({ data: { result }, status }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const getPublicationsById = (path: string, id: number, source: any) => {
	return Axios.get(`${baseURL}${path}`, {
		cancelToken: source.token
	})
		.then(({ data: { result }, status }) => {
			if (status !== 200) {
				return Promise.reject(status);
			} else {
				return Promise.resolve(result);
			}
		})
		.catch((err) => Promise.reject(err));
};

export const getByGroup = (path: string, id: number, source: any) => {
  return Axios.get(`${baseURL}${path}/${id}`, {
    cancelToken: source.token,
  })
    .then(({ data: { result }, status }) => {
      if (status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const create = (path: string, body: any, source: any) => {
  //prettier-ignore
  let {user: { token }}: any = store.getState();

  return Axios.post(`${baseURL}${path}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken: source.token,
  })
    .then(({ status, data: { result } }) => {
      if (status !== 201 && status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};


export const update = (path: string, id: number, body: any, source: any) => {
  //prettier-ignore
  let {user: { token }}: any = store.getState();

  return Axios.put(`${baseURL}${path}/${id}`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken: source.token, 
  })
    .then(({ data: { result }, status }) => {
      if (status !== 201 && status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const Delete = (path: string, id: number, source: any) => {
  //prettier-ignore
  let {user: { token }}: any = store.getState();

  return Axios.delete(`${baseURL}${path}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken: source.token,
  })
    .then(({ data: { result }, status }) => {
      if (status !== 201 && status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

//prettier-ignore
export const saveFile = (path: string, id: number, type: "image" | "pdf" | 'cva', file: any, source: any) => {
  //prettier-ignore
  let {user: { token }}: any = store.getState();
  //prettier-ignore
  return Axios.post(`${baseURL}${path}/${id}/${type}`, file, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    cancelToken: source.token,
  })
    .then(({ data: { result }, status }) => {
      if (status !== 201 && status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

export const resetPass = (path: string, id: number, source: any) => {
  //prettier-ignore
  let {user: { token }}: any = store.getState();

  return Axios.put(
    `${baseURL}${path}/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      // cancelToken: source.token,
    }
  )
    .then(({ data: { result }, status }) => {
      if (status !== 201 && status !== 200) {
        return Promise.reject(status);
      } else {
        return Promise.resolve(result);
      }
    })
    .catch((err) => Promise.reject(err));
};

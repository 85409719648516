// prettier-ignore
export const validate = ({ titleEs, titleEn, titleVa, contentEn, contentVa, contentEs}: any) => {
    let aux = "";
    if (!titleEs) aux = "Por favor rellene el nombre en Español.";
    if (!titleEn) aux = 'Por favor rellene el nombre en Inglès.';
    if (!titleVa) aux = 'Por favor rellene el nombre en Valenciano.';
    if (!contentEs) aux = "Por favor rellene la descripción en Español.";   
     if (!contentEn) aux = 'Por favor rellene la descripción en Inglès.'; 
      if (!contentVa) aux = 'Por favor rellene la descripción en Valenciano.'; 

    return aux;
  };


//middleware
import { Route } from "react-router-dom";

//views
import { ROUTES } from "../utils/routes";
import { Groups } from "../views/Logged/Groups/Groups";
import { ManageGroups } from "../views/Logged/Groups/ManageGroups/ManageGroups";
import { Degree } from "../views/Logged/Degree/Degree";
import { ManageDegrees } from "../views/Logged/Degree/ManageDegrees/ManageDegrees";
import { ResearchLines } from "../views/Logged/ResearchLines/ResearchLines";
import { ManageLines } from "../views/Logged/ResearchLines/ManageLines/ManageLines";
import { Commissions } from "../views/Logged/Commissions/Commissions";
import { ManageCommission } from "../views/Logged/Commissions/ManageCommission/ManageCommission";
import { Patents } from "../views/Logged/Patents/Patents";
import { ManagePatents } from "../views/Logged/Patents/ManagePatents/ManagePatents";
import { Offers } from "../views/Logged/Offers/Offers";
import { ManageOffer } from "../views/Logged/Offers/ManageOffer/ManageOffer";
import { Announcements } from "../views/Logged/Announcements/Announcements";
import { ManageAnnouncements } from "../views/Logged/Announcements/ManageAnnouncements/ManageAnnouncements";
import { Sliders } from "../views/Logged/Sliders/Sliders";
import { ManageSliders } from "../views/Logged/Sliders/ManageSliders/ManageSliders";
import { Resources } from "../views/Logged/Resources/Resources";
import { ManageResource } from "../views/Logged/Resources/ManageResources/ManageResource";
import { ManageFolder } from "../views/Logged/Intranet/ManageIntranet/ManageFolder";
import { Intranets } from "../views/Logged/Intranet/Intranet";

const Phds = () => {
  // prettier-ignore
  return (
    <>
      <Route exact path={ROUTES.Commissions.all} component={Commissions} />
      <Route
        exact
        path={[ROUTES.Commissions.create, ROUTES.Commissions.update]}
        component={ManageCommission}
      />

      <Route exact path={ROUTES.Announcements.all} component={Announcements} />
      <Route
        exact
        path={[ROUTES.Announcements.create, ROUTES.Announcements.update]}
        component={ManageAnnouncements}
      />
      <Route exact path={ROUTES.Intranet.all} component={Intranets} />
     
    </>
  );
};

export default Phds;

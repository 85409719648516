/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../utils/routes';
import Axios from 'axios';
import { getAll, getById } from '../../../utils/requests/dynamicReq';
import { paths } from '../../../utils/paths';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IStore } from '../../../interfaces/IStore';
import { IUser } from '../../../interfaces/IUser';

interface ILineShort {
	id: string;
	nameEs: string;
}

export const ResearchLines: FC = () => {
	const history = useHistory();

	let { staffId, isAdmin } = useSelector(({ user }: IStore) => user as IUser);

	const [isLoading, setIsLoading] = useState(false);

	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();
	const { LINES, INVESTIGATORS } = paths;
	const [lines, setLines] = useState<any>([]);
	const [search, setSearch] = useState('');
	const [filteredLines, setFilteredLines] = useState<ILineShort[]>([]);

	useEffect(() => {
		setIsLoading(true);
		isMountedRef.current = true;
		try {
			isMountedRef.current && loadLines();
		} catch (error) {
			console.log(error);
			console.log('HTTP call cancelled');
		}
		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
	}, []);

	const loadLines = async () => {
		try {
			if (isAdmin) {
				const response = await getAll(LINES, source);
				if (isMountedRef.current) {
					filterLines(response);
				}
			} else if (!isAdmin) {
				let id = staffId.toString();
				const investigator = await getById(INVESTIGATORS, id, source);
				if (isMountedRef.current) {
					filterLineByInvestigator(investigator);
				}
			}
			setIsLoading(false);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const filterLines = (rawLines: any[]) => {
		let lines: ILineShort[] = rawLines.map(
			({ id, nameEs }: ILineShort) => ({
				id,
				nameEs
			})
		);
		setLines(lines);
		setFilteredLines(lines);
	};

	const filterLineByInvestigator = (rawLines: any) => {
		// console.log(rawGroups.investigationGroup);
		if (!!rawLines.investigationLine) {
			let lines: ILineShort[] = [
				{
					id: rawLines?.investigationLine?.id,
					nameEs: rawLines?.investigationLine?.nameEs
				}
			];
			setLines(lines);
			setFilteredLines(lines);
		}
	};

	const handleSearch = (value: any) => {
		setSearch(value);
		setFilteredLines(
			lines.filter(({ nameEs }: any) =>
				nameEs.toLowerCase().includes(value.toLowerCase())
			)
		);
		!value && setFilteredLines(lines);
	};

	return (
		<div className="container-fluid p-4">
			<div className="row">
				<div className="col-12 d-flex justify-content-between">
					<h3>Acciones Transversales</h3>
					<div>
						{isAdmin && (
							<button
								className="baseBtn"
								onClick={() => history.push(`${ROUTES.Lines.create}`)}
							>
								+ Añadir
							</button>
						)}
					</div>
				</div>
			</div>
			{/* <div className="center flex-column justify-content-around text-center p-5 mt-2"> */}
			<div className="row mt-4">
				<Form.Group as={Col}>
					<InputGroup className="shadow">
						<InputGroup.Prepend>
							<InputGroup.Text className="inputGroup ">
								<FontAwesomeIcon color="#0073B5" icon={faSearch} />
							</InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							type="text"
							placeholder="Buscar..."
							className="form-control"
							value={search}
							onChange={(e) => handleSearch(e.target.value)}
						/>
					</InputGroup>
				</Form.Group>
			</div>
			<div className="row">
				<div className="col-12 ">
					<table className="table table-hover">
						<tbody>
							{filteredLines?.map(
								({ id, nameEs }: ILineShort, index: number) => (
									<tr
										key={index}
										className="pointer"
										onClick={() => history.push(`${ROUTES.Lines.all}/${id}`)}
									>
										<td>{`${nameEs}`}</td>
									</tr>
								)
							)}
						</tbody>
					</table>
					{isLoading && (
						<div className="d-flex justify-content-center">
							<div className="spinner-border mt-5" role="status" />
						</div>
					)}

					{!filteredLines.length && !isLoading && isAdmin && (
						<div className="d-flex justify-content-center mb-3">
							<h5>-No existen grupos-</h5>
						</div>
					)}

					{!filteredLines.length && !isLoading && !isAdmin && (
						<div className="d-flex justify-content-center mb-3">
							<h5>-No pertenece a ningún grupo-</h5>
						</div>
					)}
				</div>
			</div>
		</div>
		// </div>
	);
};

// prettier-ignore
export const validate = ({ nameEs, nameVa, nameEn, descriptionEs, commissionId}: any) => {
    let aux = "";
    if (!nameEs) aux = "Por favor rellene el nombre en Español.";    
     if (!nameEn) aux = 'Por favor rellene el nombre en Inglès.'; 
      if (!nameVa) aux = 'Por favor rellene el nombre en Valenciano.'; 
    if (!descriptionEs) aux = "Por favor rellene el texto en Español.";
   

    return aux;
  };


// prettier-ignore
export const validate = ({ title, image, pdf}: any) => {
    let aux = "";
    if (!title) aux = 'Por favor rellene el titulo';
    

   
   

    return aux;
  };

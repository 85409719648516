export const paths = {
  COMMUNICATIONS: "communications",
  COMMUNICATION_USER: "communications/user",
  ANNOUNCEMENTS: "announcements",
  ANNOUNCEMENTS_USER:"announcements/staff",
  INVESTIGATORS: "staff",
  GROUPS: "investigation-groups",
  LINES: "research-lines",
  INVESTROLE: "investigator-roles",
  USERROLE: "user-roles",
  USERS: "users",
  COMMTYPES: "communication-types",
  PROJECTS: "projects",
  investigatorPROJECT: "staff/project",
  SUBJECTS: "subjects",
  INVESTIGATOR_SUBJECT: "staff/subject",
  SEMINARS: "",
  PROGRAMS: "programs",
  newUsers: "staff/users",
  TEACHING_RESOURCE: "teaching-resources",
  TEACHING_RESOURCEDOCUMENTS: "teachingResources-documents/teachingResources",
  investigatorGROUP: "staff/investigation-group",
  PUBLICATIONS:"publications",
  investigatorPUBLICATION: "staff/publication",
  DEGREES: "degrees",
  DEGREE_TYPES: "degree-types",
  groupPUBLICATION: "investigation-group/publication",
  publicationsByGROUP: "publications/investigation-group",
  publicationsINVESTIGATOR: "publications/staff",
  NEWPASSWORD: "auth/change-password",
  groupSelectPUBLICATION:"investigation-group",
  userSelectPUBLICATION:"staff",
  RESETPASSWORD:"auth/reset-password/user",
  COMMISSIONS:'commissions',
  PATENTS: 'patents',
  investigatorPATENT:'staff/patents',
  OFFERS:'offers',
  STAFF_COMMISSION: "staff/commission",
  SLIDERS: "sliders",
  RESOURCES: 'resources',
  INTRANET: 'intranets',
  INTRANETFOLDER: 'intranet-folders',
  RESOURCEFOLDER: 'resource-folders'

};

import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//datepicker

import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';

//ckeditor
import {CKEditor} from 'ckeditor4-react';
import { editorConfig, SwalError } from '../../../../utils/constants';

//utils
import { FileUploader } from '../../../../components/FileUploader/FileUploader';
import {
	create,
	getAll,
	getById,
	saveFile,
	update
} from '../../../../utils/requests/dynamicReq';
import { paths } from '../../../../utils/paths';
import {
	separateRoles,
	cleanUsers,
	uniqueUsers,
	buildInvestigatorCommission
} from '../../../../utils/auxiliar/manageCommission';
import { validate } from './validations';
//middleware
import moment from 'moment';
import produce from 'immer';
import Swal from 'sweetalert2';
import Axios from 'axios';

import { ROUTES } from '../../../../utils/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDelete } from '../../../../utils/hooks/useDelete';
import { useSelector } from 'react-redux';

registerLocale('es', es);

export const ManageCommission = () => {
	let { investigatorId, isAdmin, staffId } = useSelector(({ user }) => user);

	const { COMMISSIONS, INVESTIGATORS, STAFF_COMMISSION } = paths;
	const { id } = useParams();
	const history = useHistory();
	const { deleteIt } = useDelete();

	const [isCreate, setIsCreate] = useState(false);
	const [programs, setPrograms] = useState([]);

	//userForm
	const [nameEs, setNameEs] = useState('');
	const [nameEn, setNameEn] = useState('');	
	const [nameVa, setNameVa] = useState('');	
	const [coordinators, setCoordinators] = useState([]);
	const [chosenCoordinators, setChosenCoordinators] = useState([]);
	const [members, setMembers] = useState([]);
	const [chosenMembers, setChosenMembers] = useState([]);
	const [descriptionEs, setDescriptionEs] = useState('');
	const [descriptionEn, setDescriptionEn] = useState('');
	const [descriptionVa, setDescriptionVa] = useState('');
	
	const [image, setImage] = useState();
	const [message, setMessage] = useState('');
	const [allowedEdit, setAllowedEdit] = useState(false);

	const isMounted = useRef(false);
	const source = Axios.CancelToken.source();
    const [isCoordinator, setIsCoordinator] = useState(false)
    const [isMember, setIsMember] = useState(false);

	
	
    const handleCoordinator = () => {
			const aux = chosenCoordinators.some(
				(coordinator) => coordinator.id === Number(staffId)
			);
			setIsCoordinator(aux);
		};
   const handleMemberCheck = () => {
     // Verifica si el usuario está en la lista de coordinadores o miembros
     const isCoordinator = chosenCoordinators.some(
       (coordinator) => coordinator.id === Number(staffId)
     );
     const isMember = chosenMembers.some(
       (member) => member.id === Number(staffId)
     );

     // Actualiza los estados de coordinador y miembro
     setIsCoordinator(isCoordinator);
     setIsMember(isMember);
   };

   useEffect(() => {
     handleMemberCheck();
   }, [chosenCoordinators, chosenMembers, staffId]);

	/*function checkCoordinator(staffId) {
  // Verifica si hay algún coordinador con el id igual a staffId
  const isCoordinator = chosenCoordinators.some(coordinator => coordinator.id === staffId);
  
  // Declara y asigna la constante setIsCoordinator
  const setIsCoordinator = isCoordinator;
  
  return setIsCoordinator;
} */

	    useEffect(() => {
				isMounted.current = true;
				const { pathname } = history.location;
				pathname.includes('crear') ? loadDefaultData() : loadEditData();
				return () => {
					isMounted.current = false;
					source.cancel();
				};
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, []);
			 useEffect(() => {
					handleCoordinator();
				}, [chosenCoordinators, staffId]);

	const loadDefaultData = async () => {
		try {
			let investigators = await getAll(INVESTIGATORS, source);
			
			if (isMounted.current) {
				setIsCreate(true);
				setAllowedEdit(true);
				setTimeout(() => filterAdmins(investigators), 250);
				setPrograms(programs);
			}
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const filterAdmins = (investigators) => {
		let newinvestigators = investigators.filter(
			(u) =>
				u.email !== 'admin@admin.com' &&
				u.email !== 'sombradoble@sombradoble.es'
		);
		setCoordinators(newinvestigators);
		setMembers(newinvestigators);
	};

	const loadEditData = async () => {
		try {
			let response = await getById(COMMISSIONS, id, source);			
			isMounted.current && buildObjToLoad(response);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const buildObjToLoad = async (p) => {	
		setNameEn(p.nameEn);
		setNameEs(p.nameEs);
		setNameVa(p.nameVa)
		setDescriptionEn(p.descriptionEn);
		setDescriptionEs(p.descriptionEs);	
		setDescriptionVa(p.descriptionVa)	
		setImage(p.image);			
		let investigators = await getAll(INVESTIGATORS, source);
		if (isMounted.current) {
			setIpsDisplay(p, investigators);
			setParticipantsDisplay(p, investigators);
			setAllowedEdit(true);
		}
	};

	const setIpsDisplay = async ({ staffCommissions }, investigators) => {
		const { ips, participants } = separateRoles(staffCommissions);
		setChosenCoordinators(ips);

		//get unassigned investigators by comparing with chosenIps
		let rawIps = uniqueUsers(investigators, ips);
		let unassignedIps = uniqueUsers(rawIps, participants);

		setCoordinators(cleanUsers(unassignedIps));
	};

	const setParticipantsDisplay = async (
		{ staffCommissions },
		investigators
	) => {
		const { ips, participants } = separateRoles(staffCommissions);
		setChosenMembers(participants);

		//get unassigned investigators by comparing with participants
		let rawInvs = uniqueUsers(investigators, participants);
		let unassignedInvs = uniqueUsers(rawInvs, ips);

		setMembers(cleanUsers(unassignedInvs));
	};

	//----------------------------------------

	// HANDLE INVESTIGATORS
	const addInvestigator = (coordinator, investigator) => {
		coordinator = JSON.parse(coordinator);

		investigator === 'COORDINATOR'
			? //add chosenCoordinator
			  setChosenCoordinators((state) =>
					produce(state, (drafState) => {
						drafState.push(coordinator);
					})
			  )
			: //add chosenMember
			  setChosenMembers((state) =>
					produce(state, (drafState) => {
						drafState.push(coordinator);
					})
			  );

		//remove coordinator from list
		setCoordinators((state) =>
			produce(state, (drafState) => drafState.filter(({ id }) => id !== coordinator.id))
		);

		//remove member from list
		setMembers((state) =>
			produce(state, (drafState) => drafState.filter(({ id }) => id !== coordinator.id))
		);
	};

	const removeInvestigator = (coordinator, investigator) => {
		if (!allowedEdit) return;
		investigator === 'COORDINATOR'
			? // remove chosenCoordinator
			  setChosenCoordinators((state) =>
					produce(state, (drafState) =>
						drafState.filter(({ id }) => id !== coordinator.id)
					)
			  )
			: // remove chosenParticipan
			  setChosenMembers((state) =>
					produce(state, (drafState) =>
						drafState.filter(({ id }) => id !== coordinator.id)
					)
			  );

		// add coordinator to list
		setCoordinators((state) =>
			produce(state, (drafState) => {
				drafState.push(coordinator);
			})
		);

		// add member to list
		setMembers((state) =>
			produce(state, (drafState) => {
				drafState.push(coordinator);
			})
		);
	};

	const handleSubmit = () => {
		const commission = build();	
		const notValid = validate(commission);
		if (notValid) {
			setMessage(notValid);
			setTimeout(() => setMessage(''), 4000);
			return;
		}
			let question = isCreate ? 'crear una' : 'editar esta';
		Swal.fire({
			icon: 'question',
			text: `¿Quieres ${question} comision?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(({ isConfirmed }) => isConfirmed && handleConfirmation(commission));
	};

	const handleConfirmation = (commission) => {
		let createOrUpdate = isCreate
			? create(COMMISSIONS, commission, source)
			: update(COMMISSIONS, id, commission, source);
		createOrUpdate
			.then(async ({ id: commissionId }) => {
				const investigatorCommissions = buildInvestigatorCommission(
					chosenMembers,
					chosenCoordinators,
					commissionId
				);
				await create(
					`${STAFF_COMMISSION}/${commissionId}`,
					investigatorCommissions,
					source
				);
				let createOrUpdateText = isCreate ? 'creada' : 'editada';

				Swal.fire({
					icon: 'success',
					text: `Comision ${createOrUpdateText} correctamente.`
				});
				if (typeof image !== 'string') {
					const formData = new FormData();
					formData.append('image', image);
					await saveFile(COMMISSIONS, commissionId, 'image', formData, source);
				}
				history.push(`${ROUTES.Commissions.all}/${commissionId}`);
				isMounted.current && setIsCreate(false);
			})
			.catch(() => Swal.fire({ icon: 'error', text: SwalError }));
	};

	const build = () => {
		const project = {
			nameEs,
			nameEn,		
			nameVa,
			descriptionVa,
			descriptionEs,
			descriptionEn,					
		};	
		return project;
	};

const editDeleteButtons = () => {
  if (isCreate && isAdmin) {
    return (
      <button className="baseBtn" onClick={handleSubmit}>
        Crear Comision
      </button>
    );
  }

  // Si es admin, coordinador o miembro, puede editar
  if (isAdmin || isCoordinator || isMember) {
    return (
      <>
        <button className="baseBtn" onClick={handleSubmit}>
          Editar Comision
        </button>
        <button
          className="smallBtn btn-danger ml-1"
          onClick={() =>
            deleteIt(COMMISSIONS, id, ROUTES.Commissions.all, source)
          }
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </>
    );
  }
};


	return (
		<div className="centerPage">
			<div className="container-fluid p-4">
				<div className="row">
					<div className="col d-flex justify-content-between">
						<div>
							<h3>Comision </h3>
						</div>
						<div>{editDeleteButtons()}</div>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center text-danger">
						<span>{message}</span>
					</div>
				</div>
				<div className=" form-row mt-2 ">
					<div className="col-6 d-flex justify-content-center">
						<FileUploader handleFile={setImage} image={image} />
					</div>
					<div className="col-6  d-flex flex-column  ">
						<small>*Nombre español</small>
						<input
							type="text"
							className="form-control"
							placeholder="*Nombre español"
							value={nameEs}
							onChange={({ target: { value } }) => setNameEs(value)}
							disabled={!allowedEdit}
						/>
						<small>*Nombre inglés</small>
						<input
							type="text"
							className="form-control"
							placeholder="Nombre inglés"
							maxLength="500"
							value={nameEn}
							onChange={({ target: { value } }) => setNameEn(value)}
							disabled={!allowedEdit}
						/>
						<small>*Nombre valenciano</small>
						<input
							type="text"
							className="form-control"
							placeholder="Nombre inglés"
							maxLength="500"
							value={nameVa}
							onChange={({ target: { value } }) => setNameVa(value)}
							disabled={!allowedEdit}
						/>
					</div>
				</div>
				<div className="form-row mt-4">
					<div className="col">
						<select
							className="form-control input"
							value={''}
							onChange={({ target: { value } }) =>
								addInvestigator(value, 'COORDINATOR')
							}
							disabled={!allowedEdit}
						>
							<option value={''}>Coordinadores</option>
							{coordinators?.map((i) => (
								<option key={i.id} value={JSON.stringify(i)}>
									{`${i.surname1} ${i.surname2 ? i.surname2 : ''}, ${i.name}`}
								</option>
							))}
						</select>
						{chosenCoordinators?.map((coordinator) => (
							<span
								key={coordinator.id}
								style={{ cursor: 'pointer' }}
								className="badge badge-info badge-blue-color mr-1 mt-3  "
								onClick={() => removeInvestigator(coordinator, 'COORDINATOR')}
							>
								{`${coordinator.name} ${coordinator.surname1} ${
									coordinator.surname2 ? coordinator.surname2 : ''
								}`}{' '}
								X
							</span>
						))}
					</div>
					<div className="col">
						<select
							className="form-control input"
							value={''}
							onChange={({ target: { value } }) =>
								addInvestigator(value, 'MEMBER')
							}
							disabled={!allowedEdit}
						>
							<option value={''}>Miembros</option>
							{members?.map((i) => (
								<option key={i.id} value={JSON.stringify(i)}>
									{`${i.surname1} ${i.surname2 ? i.surname2 : ''}, ${i.name}`}
								</option>
							))}
						</select>
						{chosenMembers?.map((member) => (
							<span
								key={member.id}
								style={{ cursor: 'pointer' }}
								className="badge badge-info badge-blue-color mr-1 mt-3  "
								onClick={() => removeInvestigator(member, 'MEMBER')}
							>
								{`${member.name} ${member.surname1} ${
									member.surname2 ? member.surname2 : ''
								}`}
								X
							</span>
						))}
					</div>
				</div>

				{(isCreate || allowedEdit) && (
					<>
						<div className="form-row mt-4">
							<div className="col">
								<small>*Texto en español</small>
								<CKEditor
									initData={descriptionEs}
									config={editorConfig}
									onChange={({ editor }) => setDescriptionEs(editor.getData())}
									readOnly={!allowedEdit}
								/>
							</div>
						</div>

						<div className="form-row mt-4">
							<div className="col">
								<small>Texto en inglés</small>
								<CKEditor
									initData={descriptionEn}
									config={editorConfig}
									onChange={({ editor }) => setDescriptionEn(editor.getData())}
									readOnly={!allowedEdit}
								/>
							</div>
						</div>
						<div className="form-row mt-4">
							<div className="col">
								<small>Texto en valenciano</small>
								<CKEditor
									initData={descriptionVa}
									config={editorConfig}
									onChange={({ editor }) => setDescriptionVa(editor.getData())}
									readOnly={!allowedEdit}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

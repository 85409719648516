import React, { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../utils/routes";
import Axios from "axios";
import { getAll, getById } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";

import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form, InputGroup, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { baseURL } from "../../../utils/constants";
import pnglogo from "../../../assets/images/pngLogo.png";
import logo from "../../../assets/images/logo.png";
import folderpng from '../../../assets/images/folderpng.png'

interface IIntranetShort {
  id: string;
  image: string;
  document: string;
  title: string;
  tags: string;
  type: string;
}

interface IChildFolder {
  id: number;
  name: string;
  // otras propiedades de la carpeta hija
}

interface IFolder {
  id: number;
  name: string;
  childFolders: IChildFolder[]; // Aquí defines childFolders como un array de objetos IChildFolder
  intranets: IIntranetShort[];
  parentFolder: any;
}

export const Intranets: FC = () => {
  const history = useHistory();

  let { staffId, isAdmin, userRoleId } = useSelector(
    ({ user }: IStore) => user as IUser
  );

  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const { INTRANET } = paths;
  const { INTRANETFOLDER } = paths;
  const [intranet, setIntranet] = useState<IIntranetShort[]>([]);
  const [folder, setFolder] = useState<IFolder>();
  const [folderId, setFolderId] = useState(1);
  const [search, setSearch] = useState("");
  const [typeFilter, setTypeFilter] = useState("all");
  const [filteredResources, setFilteredResources] = useState<IIntranetShort[]>(
    []
  );
  const [showEdit, setShowEdit] = useState(false)

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    try {
      isMountedRef.current && loadFolders();
      isMountedRef.current && loadResources()
    } catch (error) {
      console.log(error);
      console.log("HTTP call cancel");
    }
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, [folderId]);

  const loadFolders = async () => {
    try {
      const response = await getById(INTRANETFOLDER, folderId, source);
      console.log(response);

      if (isMountedRef.current) {
        setFolder(response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("HTTP call cancelled");
    }
  };

      const loadResources = async () => {
        try {
          const response = await getAll(INTRANET, source);
          console.log(response);

          if (isMountedRef.current) {
            filterIntranets(response);
          }
          setIsLoading(false);
        } catch (error) {
          console.log("HTTP call cancelled");
        }
      };


  const filterIntranets = (rawIntranets: IIntranetShort[]) => {
    let intranet: IIntranetShort[] = rawIntranets.map(
      ({ id, image, title, document, tags, type }: IIntranetShort) => ({
        id,
        image,
        title,
        document,
        tags,
        type,
      })
    );
    setIntranet(intranet);
    setFilteredResources(intranet);
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    applyFilters(value, typeFilter);
  };

  const handleTypeFilter = (value: string) => {
    setTypeFilter(value);
    applyFilters(search, value);
  };

  const applyFilters = (searchValue: string, typeValue: string) => {
    // Si no hay búsqueda, mostrar las carpetas y los archivos sin filtrar
    if (!searchValue.trim()) {
      setFilteredResources([]); // Resetea los recursos filtrados
      return;
    }

    // Si hay búsqueda, filtra los intranets por título, etiquetas o tipo
    let filtered = intranet.filter(
      ({ title, tags, type }) =>
        (title.toLowerCase().includes(searchValue.toLowerCase()) ||
          tags.toLowerCase().includes(searchValue.toLowerCase())) &&
        (typeValue === "all" || typeValue === type)
    );

    setFilteredResources(filtered);
  };  

  const handleCopyLink = (url: string) => {
    navigator.clipboard.writeText(url).then(
      () => {
        alert("URL copiada al portapapeles");
      },
      (err) => {
        console.error("Error al copiar la URL: ", err);
      }
    );
  };

 return (
   <div className="container-fluid p-4">
     <>
       {" "}
       <div className="row">
         <div className="col-12 d-flex justify-content-between">
           <h3>Intranet</h3>
           {userRoleId !== 4 && (
             <div>
               <button
                 className="baseBtn"
                 onClick={() => history.push(`${ROUTES.Intranet.create}`)}
               >
                 + Añadir recurso
               </button>

               <button
                 className="baseBtn  ml-10"
                 onClick={() =>
                   history.push(`${ROUTES.INTRANETFOLDERS.create}`)
                 }
               >
                 + Añadir carpeta
               </button>

               <button
                 className="baseBtn  ml-10"
                 onClick={() => setShowEdit(!showEdit)}
               >
                 + Editar carpetas
               </button>
             </div>
           )}
         </div>
       </div>
       <div className="row mt-4">
         <Form.Group as={Col}>
           <InputGroup className="shadow">
             <InputGroup.Prepend>
               <InputGroup.Text className="inputGroup ">
                 <FontAwesomeIcon color="#0073B5" icon={faSearch} />
               </InputGroup.Text>
             </InputGroup.Prepend>
             <Form.Control
               type="text"
               placeholder="Buscar..."
               className="form-control"
               value={search}
               onChange={(e) => handleSearch(e.target.value)}
             />
           </InputGroup>
         </Form.Group>
       </div>
       {/* Mostrar sistema de carpetas si el campo de búsqueda está vacío */}
       {search === "" ? (
         <>
           <h3>Carpeta actual: {folder?.name}</h3>
           {!folder?.parentFolder ? (
             ""
           ) : (
             <button
               onClick={() => setFolderId(folder?.parentFolder.id)}
               className="openBtn m-20"
             >
               {"<-"} Volver{" "}
             </button>
           )}
           <h1>Carpetas</h1>
           <div className="row">
             <div className="flex-evenly m-20 ">
               {!folder?.childFolders.length ? (
                 <div className="d-flex justify-content-center mb-3">
                   <h5>-No existen carpetas-</h5>
                 </div>
               ) : (
                 ""
               )}
               {folder?.childFolders.map((cf) => (
                 <div key={cf.id} className="m-20 flex-center">
                   <button onClick={() => setFolderId(cf?.id)}>
                     <img src={folderpng} alt="" width={150} />
                   </button>
                   <p className="text-center ">{cf.name}</p>
                   {showEdit === true ? (
                     <button
                       onClick={() =>
                         history.push(`${ROUTES.INTRANETFOLDERS.all}/${cf.id}`)
                       }
                       className="openBtn"
                     >
                       Editar carpeta
                     </button>
                   ) : (
                     ""
                   )}
                 </div>
               ))}
             </div>
           </div>

           <h1>Archivos</h1>
           <div className="row">
             <div className="flex-evenly m-20 ">
               {folder?.intranets.map(
                 (
                   { id, image, title, document, type }: IIntranetShort,
                   index: number
                 ) => {
                   const IntranetUrl =
                     type === "document"
                       ? baseURL + document
                       : type === "image"
                       ? baseURL + image
                       : "";
                   return (
                     <div key={index} className=" m-20 flex-center">
                       {userRoleId !== 4 ? (
                         <img
                           src={
                             type === "document"
                               ? pnglogo
                               : type === "image"
                               ? baseURL + image
                               : ""
                           }
                           alt=""
                           width={200}
                           onClick={() =>
                             history.push(`${ROUTES.Intranet.all}/${id}`)
                           }
                           className="pointer"
                         />
                       ) : (
                         <img
                           src={
                             type === "document"
                               ? pnglogo
                               : type === "image"
                               ? baseURL + image
                               : ""
                           }
                           alt=""
                           width={200}
                        
                         
                         />
                       )}

                       <p className="">{title}</p>
                       <div className="flex-evenly">
                         <a
                           href={IntranetUrl}
                           target="_blank"
                           rel="noreferrer"
                           className="openBtn"
                         >
                           Abrir
                         </a>
                         <Button
                           variant=""
                           onClick={() => handleCopyLink(IntranetUrl)}
                         >
                           Copiar URL
                         </Button>
                       </div>
                     </div>
                   );
                 }
               )}

               {isLoading && (
                 <div className="d-flex justify-content-center">
                   <div className="spinner-border mt-5" role="status" />
                 </div>
               )}

               {!folder?.intranets.length && !isLoading && isAdmin && (
                 <div className="d-flex justify-content-center mb-3">
                   <h5>-No existen recursos-</h5>
                 </div>
               )}

               {!folder?.intranets.length && !isLoading && !isAdmin && (
                 <div className="d-flex justify-content-center mb-3">
                   <h5>-No tiene acceso-</h5>
                 </div>
               )}
             </div>
           </div>
         </>
       ) : (
         // Mostrar resultados filtrados cuando hay una búsqueda
         <>
           <div className="row">
             <div className="flex-evenly m-20 ">
               {filteredResources?.map(
                 (
                   { id, image, title, document, type }: IIntranetShort,
                   index: number
                 ) => {
                   const resourceUrl =
                     type === "document"
                       ? baseURL + document
                       : type === "image"
                       ? baseURL + image
                       : "";
                   return (
                     <div key={index} className=" m-20 flex-center">
                       <img
                         src={
                           type === "document"
                             ? pnglogo
                             : type === "image"
                             ? baseURL + image
                             : ""
                         }
                         alt=""
                         width={200}
                         onClick={() =>
                           history.push(`${ROUTES.Resource.all}/${id}`)
                         }
                         className="pointer"
                       />
                       <p className="">{title}</p>
                       <div className="flex-evenly">
                         <a
                           href={resourceUrl}
                           target="_blank"
                           rel="noreferrer"
                           className="openBtn"
                         >
                           Abrir
                         </a>
                         <Button
                           variant=""
                           onClick={() => handleCopyLink(resourceUrl)}
                         >
                           Copiar URL
                         </Button>
                       </div>
                     </div>
                   );
                 }
               )}

               {isLoading && (
                 <div className="d-flex justify-content-center">
                   <div className="spinner-border mt-5" role="status" />
                 </div>
               )}

               {!filteredResources.length && !isLoading && isAdmin && (
                 <div className="d-flex justify-content-center mb-3">
                   <h5>-No existen recursos-</h5>
                 </div>
               )}

               {!filteredResources.length && !isLoading && !isAdmin && (
                 <div className="d-flex justify-content-center mb-3">
                   <h5>-No tiene acceso-</h5>
                 </div>
               )}
             </div>
           </div>
         </>
       )}
     </>
   </div>
 );

};

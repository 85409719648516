import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

//datepicker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

//middelware
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FileUploader } from '../../../../components/FileUploader/FileUploader';
import Axios from 'axios';
import moment from 'moment';

//ckeditor
import { CKEditor } from 'ckeditor4-react';
import { editorConfig, SwalError } from '../../../../utils/constants';

import {
	create,
	getAll,
	getById,
	saveFile,
	update
} from '../../../../utils/requests/dynamicReq';
import { ROUTES } from '../../../../utils/routes';
import Swal from 'sweetalert2';
import { paths } from '../../../../utils/paths';
import { useDelete } from '../../../../utils/hooks/useDelete';
import { validate } from './validations';

export const ManageSliders = () => {
	const { SLIDERS } = paths;
	const { id } = useParams();
	const { deleteIt } = useDelete();

	let { staffId, isAdmin, userId, userRoleId } = useSelector(({ user }) => user);

	const history = useHistory();

	const [isCreate, setIsCreate] = useState(false);
	const [url, setUrl] = useState('');
	
	
	const [textEs, setTextEs] = useState('');
	const [textEn, setTextEn] = useState('');
	const [textVa, setTextVa] = useState('');
	

	const [image, setImage] = useState();
	const [message, setMessage] = useState('');
	const [allowedEdit, setAllowedEdit] = useState(false);

	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();

	useEffect(() => {
		isMountedRef.current = true;
		const { pathname } = history.location;
		pathname.includes('crear') ? setIsCreate(true) : loadData();
		
		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	

	const loadData = async () => {
		try {
			let response = await getById(SLIDERS, id, source);
			isMountedRef.current && buildObjToLoad(response);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	// prettier-ignore
	const buildObjToLoad = (response) => {
    const { textEn, textEs, textVa, url, image} = response;
  


   
    setTextEn(textEn);
    setTextEs(textEs);
	setTextVa(textVa)
    setUrl(url);

    setImage(image);


	if (isMountedRef.current) 
	setAllowedEdit(true);
  };

	const handleSubmit = () => {
		const slider = build();
		const notValid = validate(slider);
		if (notValid) {
			setMessage(notValid);
			setTimeout(() => setMessage(''), 4000);
			return;
		}
		let question = isCreate ? 'crear un' : 'editar este';
		Swal.fire({
			icon: 'question',
			text: `¿Quieres ${question} Slide?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(({ isConfirmed }) => {
			isConfirmed && handleConfirmation(slider);
		});
	};

	const handleConfirmation = (sliders) => {
		let createOrUpdate = isCreate
			? create(SLIDERS, sliders, source)
			: update(SLIDERS, id, sliders, source);
		createOrUpdate
			.then(async ({ id }) => {
				Swal.fire({
					icon: 'success',
					text: `Slide  ${isCreate ? 'creado' : 'editado'} correctamente.`
				});
				if (typeof image !== 'string') {
					const formData = new FormData();
					formData.append('image', image);
					await saveFile(SLIDERS, id, 'image', formData, source);
				}
				history.push(`${ROUTES.Sliders.all}/${id}`);
				setIsCreate(false);
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					text: SwalError
				});
			});
	};

	const build = () => ({		
		textEs,
		textEn,	
		textVa,
		url,
		
	});

	const editDeleteButtons = () => {
	
		if (!isCreate || isAdmin) {
			return (
				<>
					<button className="baseBtn" onClick={handleSubmit}>
						Editar Slider
					</button>
					{/* <button
						className="smallBtn btn-danger ml-1"
						onClick={() =>
							deleteIt(SLIDERS, id, ROUTES.Sliders.all, source)
						}
					>
						
					</button> */}
				</>
			);
		}
	};

	return (
		<div className="centerPage">
			{(isAdmin || userRoleId === 2) && (
				<div className="container-fluid p-4">
					<div className="row">
						<div className="col d-flex justify-content-between">
							<div>
								<h3>Slider </h3>
							</div>
							<div>{editDeleteButtons()}</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-12 text-center text-danger">
							<span>{message}</span>
						</div>
					</div>
					<div className="form-row mt-2">
						<div className="col-6 d-flex  justify-content-center">
							<FileUploader handleFile={setImage} image={image} />
						</div>

						<div className="col-6  d-flex flex-column  justify-content-center">
							<small>Link</small>
							<input
								type="text"
								className="form-control"
								placeholder="Link"
								value={url}
								onChange={({ target: { value } }) => {
									setUrl(value);
								}}
							/>
						</div>
					</div>

					<div className="form-row mt-2">
						<div className="col">
							<small>*Texto en español</small>
							<input
								type="text"
								className="form-control"
								placeholder="*Título español"
								value={textEs}
								onChange={({ target: { value } }) => {
									setTextEs(value);
								}}
							/>
						</div>
						<div className="col">
							<small>*Texto en inglés</small>
							<input
								type="text"
								className="form-control"
								placeholder="Título inglés"
								value={textEn}
								onChange={({ target: { value } }) => {
									setTextEn(value);
								}}
							/>
						</div>
						<div className="col">
							<small>*Texto en valenciano</small>
							<input
								type="text"
								className="form-control"
								placeholder="Título inglés"
								value={textVa}
								onChange={({ target: { value } }) => {
									setTextVa(value);
								}}
							/>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

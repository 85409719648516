/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

//ckeditor
import {CKEditor} from 'ckeditor4-react';
import { editorConfig, SwalError } from '../../../../utils/constants';

import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Axios from 'axios';
import {
	create,
	getAll,	
	getById,	
	update
} from '../../../../utils/requests/dynamicReq';
import { paths } from '../../../../utils/paths';
import { useDelete } from '../../../../utils/hooks/useDelete';

import { Col, Form, InputGroup } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { produce } from 'immer';
import { requiredInputs } from '../../../../utils/constants';
import { ROUTES } from '../../../../utils/routes';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../interfaces/IStore';
import { IUser } from '../../../../interfaces/IUser';
import { ILine } from '../../../../interfaces/ILine';
import { validate } from './validations';
import { IPublication } from '../../../../interfaces/IPublication';
import moment from 'moment';
import parse from 'html-react-parser';

export const ManageLines: FC = () => {
	let { staffId, isAdmin } = useSelector(({ user }: IStore) => user as IUser);

	const { id }: any = useParams();
	const history = useHistory();
	const { deleteIt } = useDelete();

	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();
	const {
		INVESTIGATORS,
		LINES,
			
	} = paths;

	const [isCreate, setIsCreate] = useState(false);
	const [isIpUser, setIsIpUser] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	//Group form
	const [nameEs, setNameEs] = useState('');
	const [nameEn, setNameEn] = useState('');	
	const [nameVa, setNameVa] = useState('')		
	const [descriptionEs, setDescriptionEs] = useState('');
	const [descriptionEn, setDescriptionEn] = useState('');
	const [descriptionVa, setDescriptionVa] = useState('');
    const [keywordsEs, setKeywordsEs] = useState('');
    const [keywordsEn, setKeywordsEn] = useState('');
    const [keywordsVa, setKeywordsVa] = useState('');


	const [message, setMessage] = useState('');
	const [allowedEdit, setAllowedEdit] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		isMountedRef.current = true;
		checkIfIsEdit();
		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
	}, []);

	const checkIfIsEdit = () => {
		const { pathname }: any = history.location;
		if (pathname.includes('crear')) {
			setIsCreate(true);
			loadDefaultData(true);
		} else {
			loadDefaultData(false);
			setIsCreate(false);
		}
	};

	const loadDefaultData = async (isCreate: boolean) => {
		try {
			let investigators = await getAll(INVESTIGATORS, source);
					
			if (isMountedRef.current) {				
			
				if (!isCreate) {
					loadLine();
				}else
				setAllowedEdit(true);

			}
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const loadLine = async () => {
		try {
			const {
				nameEs,
				nameEn,
				nameVa,						
			    keywordsEs,
				keywordsEn,
				keywordsVa,
				descriptionEs,
				descriptionEn,
				descriptionVa
				
			} = await getById(LINES, id, source);		
			if (isMountedRef.current) {
				setNameEs(nameEs);
				setNameEn(nameEn);	
				setNameVa(nameVa);	
                setKeywordsEs(keywordsEs)	
				setKeywordsEn(keywordsEn)		
				setKeywordsVa(keywordsVa)				
				setDescriptionEs(descriptionEs);
				setDescriptionEn(descriptionEn);
				setDescriptionVa(descriptionVa);
						
				setAllowedEdit(true);
			}
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};


	

	//----------------
	const handleSubmit = () => {
		
		const line: ILine = buildLine();		
        const notValid = validate(line);
		if (notValid) {
			setMessage(notValid);
			setTimeout(() => setMessage(''), 4000);
			return;
		}
		let question = isCreate ? 'crear una' : 'editar esta';
		Swal.fire({
			icon: 'question',
			text: `¿Quieres ${question} acción tranversal?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(({ isConfirmed }) => {
			isConfirmed && handleConfirmation(line);
		});
	};

	const handleConfirmation = (
		line: ILine,
		
		
	) => {
		let createOrUpdate = isCreate
			? create(LINES, line, source)
			: update(LINES, id, line, source);
		createOrUpdate
			.then(async ({ id: lineId }) => {				
				let createOrUpdateText = isCreate ? 'creada' : 'editada';
				Swal.fire({
					icon: 'success',
					text: `Accion transversal ${createOrUpdateText} correctamente.`
				});
			
				history.push(`${ROUTES.Lines.all}/${lineId}`);
				isMountedRef.current && setIsCreate(false);
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					text: SwalError
				});
			});
	};

	

	

	const buildLine = () => ({
		nameEs,
		nameEn,
		nameVa,
		descriptionEs,
		descriptionEn,		
		descriptionVa,
        keywordsEs,
		keywordsEn,
		keywordsVa
	});

	
	const editDeleteButtons = () => {
		if (isCreate) {
			return (
				<button className="baseBtn" onClick={handleSubmit}>
					Crear Acción Transversal
				</button>
			);
		}
		//if is update && logged user included as IP
		if (!isCreate || isAdmin) {
			return (
				<>
					<button className="baseBtn" onClick={handleSubmit}>
						Editar Acción Transversal
					</button>
					<button
						className="smallBtn btn-danger ml-1"
						onClick={() => deleteIt(LINES, id, ROUTES.Lines.all, source)}
					>
						<FontAwesomeIcon icon={faTrash} />
					</button>
				</>
			);
		}
	};

	//----------------
	//HANDLE SELECTED PUBLICATIONS

	return (
		<div className="centerPage">
			<div className="container-fluid ">
				<div className="row">
					<div className="col d-flex justify-content-between">
						<div>
							<h3>Acción Transversal</h3>
						</div>
						<div>{editDeleteButtons()}</div>
					</div>
				</div>
			</div>
			<div className="row mt-4">
				<div className="col-12 text-center text-danger">
					<span>{message}</span>
				</div>
			</div>
			<div className="form-row mt-4">
				<div className="col">
					<small>Nombre español*</small>
					<input
						type="text"
						className="form-control input"
						placeholder="Nombre español"
						value={nameEs}
						onChange={({ target: { value } }) => {
							setNameEs(value);
						}}
						disabled={!allowedEdit}
					/>
					<small>Nombre inglés*</small>
					<input
						type="text"
						className="form-control mt-4 input"
						placeholder="Nombre inglés"
						value={nameEn ? nameEn : ''}
						onChange={({ target: { value } }) => {
							setNameEn(value);
						}}
						disabled={!allowedEdit}
					/>
						<small>Nombre Valenciano*</small>
					<input
						type="text"
						className="form-control mt-4 input"
						placeholder="Nombre inglés"
						value={nameVa ? nameVa : ''}
						onChange={({ target: { value } }) => {
							setNameVa(value);
						}}
						disabled={!allowedEdit}
					/>
				</div>
			</div>		
			
			{(isCreate || allowedEdit ) && 
				(<>

			<div className="form-row mt-4">
				<div className="col">
					<small>Descripción en español*</small>
					<CKEditor
						initData={descriptionEs}
						config={editorConfig}
						onChange={({ editor }: { editor: any }) =>
							setDescriptionEs(editor.getData())
						}
						readOnly={!allowedEdit}
					/>
				</div>
			</div>

			<div className="form-row mt-4">
				<div className="col">
					<small>Descripción en inglés*</small>
					<CKEditor
						initData={descriptionEn}
						config={editorConfig}
						onChange={({ editor }: { editor: any }) =>
							setDescriptionEn(editor.getData())
						}
						readOnly={!allowedEdit}
					/>
				</div>
			</div>
			<div className="form-row mt-4">
				<div className="col">
					<small>Descripción en valenciano*</small>
					<CKEditor
						initData={descriptionVa}
						config={editorConfig}
						onChange={({ editor }: { editor: any }) =>
							setDescriptionVa(editor.getData())
						}
						readOnly={!allowedEdit}
					/>
				</div>
			</div>
</>)}

			<div className="col">
				<small>Palabras clave Español* (posicionamiento SEO)</small>
				<input
					type="text"
					className="form-control"
					placeholder="*Por favor separe cada palabra por una coma (e.g. océano, mar, olas)"
					value={keywordsEs}
					onChange={({ target: { value } }) => {
						setKeywordsEs(value);
					}}
				/>
					<small>Palabras clave Ingles* (posicionamiento SEO)</small>
				<input
					type="text"
					className="form-control"
					placeholder="*Por favor separe cada palabra por una coma (e.g. océano, mar, olas)"
					value={keywordsEn}
					onChange={({ target: { value } }) => {
						setKeywordsEn(value);
					}}
				/>
					<small>Palabras clave Valenciano* (posicionamiento SEO)</small>
				<input
					type="text"
					className="form-control"
					placeholder="*Por favor separe cada palabra por una coma (e.g. océano, mar, olas)"
					value={keywordsVa}
					onChange={({ target: { value } }) => {
						setKeywordsVa(value);
					}}
				/>
			</div>
		</div>
	);
};

import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Col from 'react-bootstrap/esm/Col';
import Form from 'react-bootstrap/esm/Form';
import InputGroup from 'react-bootstrap/esm/InputGroup';
import Collapse from 'react-bootstrap/esm/Collapse';
import { ROUTES } from '../../../utils/routes';
import Axios from 'axios';
import {
	getById,
	getPublicationsById,
	update
} from '../../../utils/requests/dynamicReq';
import { paths } from '../../../utils/paths';
import parse from 'html-react-parser';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Swal from 'sweetalert2';
import { SwalError } from '../../../utils/constants';
import { create } from '../../../utils/requests/dynamicReq';

export const Publications = () => {
	const { staffId, isAdmin, token } = useSelector((state) => state.user);
	const user = useSelector((state) => state.user)

	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();
	const { PUBLICATIONS, investigatorPUBLICATION, groupPUBLICATION, INVESTIGATORS } = paths;
	const [publications, setPublications] = useState({
		myPublications: [],
		othersPublications: []
	});
	const [displayPublications, setDisplayPublications] = useState({
		myPublications: [],
		othersPublications: []
	});

	const [openPending, setOpenPending] = useState(true);
	const [openValidated, setOpenValidated] = useState(true);
	const [openOthers, setOpenOthers] = useState(true);
	const [selectedPublications, setSelectedPublications] = useState([]);

	useEffect(() => {
		setIsLoading(true);
		isMountedRef.current = true;
		loadData();

		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadData = async () => {
		try {
			const response = await getPublicationsById(
				`${PUBLICATIONS}/staff/${staffId}/divided`,
				staffId,
				source
			);
			if (isMountedRef.current) {
				setPublications(response);
				setDisplayPublications(response);
			}
			setIsLoading(false);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const searchBar = (value) => {
		const searchValue = value.toLowerCase();
		const filteredMyPublications = publications.myPublications.filter(
			({ title, authors }) => (title.toLowerCase().includes(searchValue) || authors.toLowerCase().includes(searchValue))
		);
		const filteredOthersPublications = publications.othersPublications.filter(
			({ title, authors }) => (title.toLowerCase().includes(searchValue) || authors.toLowerCase().includes(searchValue))
		);
		setDisplayPublications({
			myPublications: filteredMyPublications,
			othersPublications: filteredOthersPublications
		});
		if (!value) {
			setDisplayPublications(publications);
		}
	};

	const pendingPublications = displayPublications?.myPublications?.filter(
		(publication) => publication.status === 'pending'
	);
	const validatedPublications = displayPublications?.myPublications?.filter(
		(publication) => publication.status === 'validated'
	);

	const handleCheckboxChange = (id) => {
		setSelectedPublications((prevSelected) =>
			prevSelected.includes(id)
				? prevSelected.filter((pubId) => pubId !== id)
				: [...prevSelected, id]
		);
	};



	const validatePublication = async (e) => {

		Swal.fire({
			title: 'Validar',
			text: 'Esta seguro que desea validar esta publicacion?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(async ({ isConfirmed }) => {
			if (isConfirmed) {
				try {
					const staff = await getById(INVESTIGATORS, staffId, source)
					update(
						PUBLICATIONS,
						e.target.value,
						{ status: 'validated' },
						token,
						source
					)
						.then(() => {
							create(
								`${investigatorPUBLICATION}/${e.target.value}`,
								[
									{
										staffId: staffId,
										publicationId: parseInt(e.target.value),
										selected: false
									}
								],
								source
							);
						})
						.then(() => {
							create(
								`${groupPUBLICATION}/${e.target.value}`,
								[
									{
										investigationGroupId: staff?.investigationGroup?.id,
										publicationId: parseInt(e.target.value),
										selected: false
									}
								],
								source
							);
						})
						.then(() => {
							Swal.fire({
								text: 'Se ha validado la publicacion',
								icon: 'success'
							});
							setTimeout(function () {
								window.location.reload(true);
							}, 2000);
						});
				} catch (error) {
					Swal.fire({
						icon: 'error',
						text: SwalError
					});
				}
			} else {
				Swal.fire({ text: 'No se ha validado la publicacion', icon: 'info' });
			}
		});
	};

	const excludePublication = async (e) => {
		Swal.fire({
			title: 'Excluir',
			text: 'Esta seguro que desea excluir esta publicacion?, esta accion no puede deshacerse',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(async ({ isConfirmed }) => {
			if (isConfirmed) {
				try {
					update(
						PUBLICATIONS,
						e.target.value,
						{ status: 'excluded' },
						token,
						source
					).then(() => {
						Swal.fire({
							text: 'Se ha excluido la publicacion',
							icon: 'success'
						});
						setTimeout(function () {
							window.location.reload(true);
						}, 2000);
					});
				} catch (error) {
					Swal.fire({
						icon: 'error',
						text: SwalError
					});
				}
			} else {
				Swal.fire({ text: 'No se ha excluido la publicacion', icon: 'info' });
			}
		});
	};

	const validateSelectedPublications = async () => {
		Swal.fire({
			title: 'Validar',
			text: '¿Está seguro que desea validar las publicaciones seleccionadas?',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Sí',
			cancelButtonText: 'No'
		}).then(async ({ isConfirmed }) => {
			if (isConfirmed) {
				try {
					for (const id of selectedPublications) {
						const staff = await getById(INVESTIGATORS, staffId, source);
						await update(
							PUBLICATIONS,
							id,
							{ status: 'validated' },
							token,
							source
						);
						await create(
							`${investigatorPUBLICATION}/${id}`,
							[
								{
									staffId: staffId,
									publicationId: parseInt(id),
									selected: false
								}
							],
							source
						);
						await create(
							`${groupPUBLICATION}/${id}`,
							[
								{
									investigationGroupId: staff?.investigationGroup?.id,
									publicationId: parseInt(id),
									selected: false
								}
							],
							source
						);
					}
					Swal.fire({
						text: 'Se han validado las publicaciones seleccionadas',
						icon: 'success'
					});
					setSelectedPublications([]);
					loadData();
				} catch (error) {
					Swal.fire({
						icon: 'error',
						text: SwalError
					});
				}
			} else {
				Swal.fire({
					text: 'No se han validado las publicaciones',
					icon: 'info'
				});
			}
		});
	};

	const excludeSelectedPublications = async () => {
		Swal.fire({
			title: 'Excluir',
			text: '¿Está seguro que desea excluir las publicaciones seleccionadas? Esta acción no puede deshacerse',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Sí',
			cancelButtonText: 'No'
		}).then(async ({ isConfirmed }) => {
			if (isConfirmed) {
				try {
					for (const id of selectedPublications) {
						await update(
							PUBLICATIONS,
							id,
							{ status: 'excluded' },
							token,
							source
						);
					}
					Swal.fire({
						text: 'Se han excluido las publicaciones seleccionadas',
						icon: 'success'
					});
					setSelectedPublications([]);
					loadData();
				} catch (error) {
					Swal.fire({
						icon: 'error',
						text: SwalError
					});
				}
			} else {
				Swal.fire({
					text: 'No se han excluido las publicaciones',
					icon: 'info'
				});
			}
		});
	};


	const removeParticipation = async (e) => {
		//Obtengo publicacion en cuestión para quitar participacion
		let publicationBeignRemoved = displayPublications?.othersPublications?.filter(
			(item) => item.id !== e.target.value
		);
		// Obtengo array de staffPublications
		let staffPublications = publicationBeignRemoved[0]?.staffPublications;
		// Quito participacion de staffPublications
		let staffPublicationsRemovedParticipation = staffPublications.filter(
			(item) => item.staff?.id !== staffId
		);

		//Armo array para staff-publications
		const newStaffPublicationArray = staffPublicationsRemovedParticipation.map((item) => ({
			staffId: item.staff.id,
			publicationId: parseInt(e.target.value),
			selected: item.selected,
		}));


		// console.log("publicationBeignRemoved:", publicationBeignRemoved[0]);


		//Armo array para investigationGroup-publication
		const seenIds = new Set();
		const newInvestigationGroupPublications = publicationBeignRemoved[0].staffPublications
			.filter(staffIntermediate => staffIntermediate.staff.id !== staffId)
			.filter(staffIntermediate => staffIntermediate.staff.investigationGroup?.id)
			.filter(staffIntermediate => {
				if (seenIds.has(staffIntermediate.staff.investigationGroup.id)) {
					return false;
				} else {
					seenIds.add(staffIntermediate.staff.investigationGroup.id);
					return true;
				}
			})
			.map(staffIntermediate => ({
				investigationGroupId: staffIntermediate.staff.investigationGroup.id,
				publicationId: publicationBeignRemoved[0].id,
			}));

		// Le agregamos los selected desde BD
		const investigationGroupPublicationsWithSelected = newInvestigationGroupPublications.map(newInvestigationGroupPublication => {
			const match = publicationBeignRemoved[0].investigationGroupPublications.find(intermediateGroup => intermediateGroup.investigationGroup.id === newInvestigationGroupPublication.investigationGroupId);
			return {
				...newInvestigationGroupPublication,
				selected: match ? match.selected : false
			};
		});

		Swal.fire({
			title: 'Quitar participación',
			text: 'Esta seguro que desea quitar su participación de esta publicación? Esta acción no puede deshacerse',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(async ({ isConfirmed }) => {
			if (isConfirmed) {
				try {
					await create(
						`${investigatorPUBLICATION}/${e.target.value}`,
						newStaffPublicationArray,
						source
					)
					await create(
						`${groupPUBLICATION}/${e.target.value}`,
						investigationGroupPublicationsWithSelected,
						source
					)
						.then(() => {
							Swal.fire({
								text: 'Se ha removido su participación en la publicacion',
								icon: 'success'
							});
							setTimeout(function () {
								window.location.reload(true);
							}, 2000);
						});
				} catch (error) {
					Swal.fire({
						icon: 'error',
						text: SwalError
					});
				}
			} else {
				Swal.fire({ text: 'No se ha removido su participación la publicacion', icon: 'info' });
			}
		});
	};


	return (
		<div className="container-fluid p-4">
			<div className="row">
				<div className="col d-flex justify-content-between">
					<div>
						<h3>Publicaciones</h3>
					</div>
					{/* <div>
						<button
							className="baseBtn"
							onClick={() => history.push(ROUTES.Publications.create)}
						>
							+ Añadir
						</button>
					</div> */}
				</div>
			</div>
			<div className="row mt-4">
				<Form.Group as={Col}>
					<InputGroup className="shadow">
						<InputGroup.Prepend>
							<InputGroup.Text className="inputGroup ">
								<FontAwesomeIcon color="#0073B5" icon={faSearch} />
							</InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							type="text"
							placeholder="Buscar..."
							className="form-control"
							onChange={({ target: { value } }) => searchBar(value)}
						/>
					</InputGroup>
				</Form.Group>
			</div>
			<div className="mt-3 mb-3 center">
				{selectedPublications.length > 0 && (
					<>
						<button
							className=" mx-2 greenBtn"
							onClick={validateSelectedPublications}
						>
							Validar Seleccionadas
						</button>
						<button
							className=" redBtn"
							onClick={excludeSelectedPublications}
						>
							Excluir Seleccionadas
						</button>
					</>
				)}
			</div>
			<div className="row">
				<div className="col-12 ">
					<p>
						<b>Mis publicaciones pendientes</b> ({pendingPublications?.length})
						<button
							className="btn btn-link"
							onClick={() => setOpenPending(!openPending)}
							aria-controls="collapse-pending"
							aria-expanded={openPending}
						>
							{openPending ? 'Colapsar' : 'Mostrar'}
						</button>
					</p>{' '}
					<Collapse in={openPending}>
						<div id="collapse-pending">
							<table className="table ">
								<tbody>
									{pendingPublications?.map(
										({ id, title, authors, year, titleChapter, journal }) => (
											<tr key={id}>
												<Form.Check
													type="checkbox"
													id={`checkbox-${id}`}
													onChange={() => handleCheckboxChange(id)}
													checked={selectedPublications.includes(id)}
												/>
												<td>
													{titleChapter ? (
														<>
															<h6>{titleChapter && parse(titleChapter)}</h6>
															<h6 className="text-secondary">
																{title && parse(title)}
															</h6>
														</>
													) : (
														<h6>{title && parse(title)}</h6>
													)}
													<span>
														{authors}{', '}
														{journal ?? "Preprint"}{', '}
														{year}
													</span>
												</td>
												<div className="flex-evenly w-max">
													<button
														value={id}
														onClick={validatePublication}
														className="btnMargin greenBtn"
													>
														Validar
													</button>
													<button
														value={id}
														onClick={excludePublication}
														className="btnMargin redBtn"
													>
														Excluir
													</button>
												</div>
											</tr>
										)
									)}
									{isLoading && (
										<div className="d-flex justify-content-center">
											<div className="spinner-border mt-5" role="status" />
										</div>
									)}

									{!pendingPublications?.length && !isLoading && (
										<div className="d-flex justify-content-center mb-3">
											<h5>-No hay publicaciones pendientes-</h5>
										</div>
									)}
								</tbody>
							</table>
						</div>
					</Collapse>
					<p>
						<b>Mis publicaciones validadas</b> ({validatedPublications?.length})
						<button
							className="btn btn-link"
							onClick={() => setOpenValidated(!openValidated)}
							aria-controls="collapse-validated"
							aria-expanded={openValidated}
						>
							{openValidated ? 'Colapsar' : 'Mostrar'}
						</button>
					</p>
					<Collapse in={openValidated}>
						<div id="collapse-validated">
							<table className="table table-hover">
								<tbody>
									{validatedPublications?.map(
										({ id, title, authors, year, titleChapter, journal }) => (
											<tr key={id}>
												<td>
													{titleChapter ? (
														<>
															<h6>{titleChapter && parse(titleChapter)}</h6>
															<h6 className="text-secondary">
																{title && parse(title)}
															</h6>
														</>
													) : (
														<h6>{title && parse(title)}</h6>
													)}
													<span>
														{authors}{', '}
														{journal ?? "Preprint"}{', '}
														{year}
													</span>
												</td>
												<button
													className="btnMargin blueBtn"
													onClick={() =>
														history.push(`${ROUTES.Publications.all}/${id}`)
													}
												>
													Administrar
												</button>
											</tr>
										)
									)}
									{isLoading && (
										<div className="d-flex justify-content-center">
											<div className="spinner-border mt-5" role="status" />
										</div>
									)}

									{!validatedPublications?.length && !isLoading && (
										<div className="d-flex justify-content-center mb-3">
											<h5>-No hay publicaciones validadas-</h5>
										</div>
									)}
								</tbody>
							</table>
						</div>
					</Collapse>
					<p>
						<b>Publicaciones de otros vinculadas a mi</b> (
						{displayPublications?.othersPublications?.length})
						<button
							className="btn btn-link"
							onClick={() => setOpenOthers(!openOthers)}
							aria-controls="collapse-others"
							aria-expanded={openOthers}
						>
							{openOthers ? 'Colapsar' : 'Mostrar'}
						</button>
					</p>
					<Collapse in={openOthers}>
						<div id="collapse-others">
							<table className="table table-hover">
								<tbody>
									{displayPublications?.othersPublications?.map(
										(publication) => (
											<tr key={publication.id}>
												<td>
													{publication.titleChapter ? (
														<>
															<h6>
																{publication.titleChapter &&
																	parse(publication.titleChapter)}
															</h6>
															<h6 className="text-secondary">
																{publication.title && parse(publication.title)}
															</h6>
														</>
													) : (
														<h6>
															{publication.title && parse(publication.title)}
														</h6>
													)}
													<span>
														{publication.authors}{', '}
														{publication.journal ?? "Preprint"}{', '}
														{publication.year}
													</span>
												</td>
												<button
													className="redBtn"
													value={publication?.id}
													onClick={removeParticipation}
												>
													Quitar participación
												</button>
											</tr>
										)
									)}
									{isLoading && (
										<div className="d-flex justify-content-center">
											<div className="spinner-border mt-5" role="status" />
										</div>
									)}

									{!displayPublications?.othersPublications?.length &&
										!isLoading && (
											<div className="d-flex justify-content-center mb-3">
												<h5>-No hay publicaciones de otros vinculadas a mi-</h5>
											</div>
										)}
								</tbody>
							</table>
						</div>
					</Collapse>
				</div>
			</div>
		</div>
	);
};

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

//ckeditor
import { CKEditor } from "ckeditor4-react";
import { editorConfig, SwalError } from '../../../../utils/constants';

import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Axios from 'axios';
import {
	create,
	getAll,
	getByGroup,
	getById,
	saveFile,
	update
} from '../../../../utils/requests/dynamicReq';
import { paths } from '../../../../utils/paths';
import { FileUploader } from '../../../../components/FileUploader/FileUploader';
import { useDelete } from '../../../../utils/hooks/useDelete';
import { investigatorRoles } from '../../../../utils/roles';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faStar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { produce } from 'immer';
import { requiredInputs } from '../../../../utils/constants';
import { ROUTES } from '../../../../utils/routes';
import { useSelector } from 'react-redux';
import { IStore } from '../../../../interfaces/IStore';
import { IUser } from '../../../../interfaces/IUser';
import { IPatent } from '../../../../interfaces/IPatent';
import { validate } from './validations';


export const ManagePatents: FC = () => {
	let { staffId, isAdmin, userRoleId } = useSelector(({ user }: IStore) => user as IUser);

	const { id }: any = useParams();
	const history = useHistory();
	const { deleteIt } = useDelete();

	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();
	const { INVESTIGATORS, PATENTS, investigatorPATENT } = paths;

	const [isCreate, setIsCreate] = useState(false);
	const [isIpUser, setIsIpUser] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	//Group form
	const [titleEs, setTitleEs] = useState('');
	const [titleEn, setTitleEn] = useState('');
	const [titleVa, setTitleVa] = useState('');

	const [image, setImage] = useState();
	const [contentEs, setContentEs] = useState('');
	const [contentEn, setContentEn] = useState('');
	const [contentVa, setContentVa] = useState('');

	const [staff, setStaff] = useState<any[]>([]);
	const [chosenStaff, setChosenStaff] = useState<any[]>([]);



	const [message, setMessage] = useState('');

	const [allowedEdit, setAllowedEdit] = useState(false);

	useEffect(() => {
		setIsLoading(true);
		isMountedRef.current = true;
		checkIfIsEdit();
		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
	}, []);

	const checkIfIsEdit = () => {
		const { pathname }: any = history.location;
		if (pathname.includes('crear')) {
			setIsCreate(true);
			loadDefaultData(true);
		} else {
			loadDefaultData(false);
			setIsCreate(false);
		}
	};

	const loadDefaultData = async (isCreate: boolean) => {
		try {
			let staff = await getAll(INVESTIGATORS, source);

			if (isMountedRef.current) {
				setStaff(staff);
				if (!isCreate) {
					loadPatent();
				} else
				setAllowedEdit(true);
				
			}
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const loadPatent = async () => {
		try {
			const {
				titleEs,
				titleEn,
				titleVa,
				staff,
				contentEs,
				contentEn,
				contentVa,
				image,
			} = await getById(PATENTS, id, source);

			if (isMountedRef.current) {
				setTitleEs(titleEs);
				setTitleEn(titleEn);
				setTitleVa(titleVa);
				setContentEs(contentEs);
				setContentEn(contentEn);
				setContentVa(contentVa);
				setImage(image);
				setChosenStaff(staff)
				newStaff(staff, staff)
				
				setAllowedEdit(true);
			}
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const newStaff = (u: any[], staff: any[]) => {
		// eslint-disable-next-line array-callback-return
		u.map((r: any) => {
		  setStaff((state) =>
			produce(state, (drafState) => drafState.filter(({ id }) => id !== r.id))
		  );
		});
	  };

	//----------------
	const handleSubmit = () => {
		const staffIds = selectStaffIds();
		const patent: IPatent = buildPatent(staffIds);
           const notValid = validate(patent);
						if (notValid) {
							setMessage(notValid);
							setTimeout(() => setMessage(''), 4000);
							return;
						}
	
		let question = isCreate ? 'crear una' : 'editar esta';
		Swal.fire({
			icon: 'question',
			text: `¿Quieres ${question} patente?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(({ isConfirmed }) => {
			isConfirmed && handleConfirmation(patent, image);
		});
	};

	const handleConfirmation = (
		patent: IPatent,
		image: any
	) => {
		let createOrUpdate = isCreate
			? create(PATENTS, patent, source)
			: update(PATENTS, id, patent, source);
		createOrUpdate
			.then(async ({ id: patentId }) => {
				let createOrUpdateText = isCreate ? 'creada' : 'editada';
				Swal.fire({
					icon: 'success',
					text: `Patente ${createOrUpdateText} correctamente.`
				});
				if (typeof image !== 'string') {
					const formData = new FormData();
					formData.append('image', image);
					await saveFile(PATENTS, patentId, 'image', formData, source);
				}
				history.push(`${ROUTES.Patents.all}/${patentId}`);
				isMountedRef.current && setIsCreate(false);
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					text: SwalError
				});
			});
	};

	const buildPatent = (staffIds: number[]) => ({
		titleEs,
		titleEn,
		titleVa,
		contentEs,
		contentEn,
		contentVa,
		staffIds
	});

	//----------------
	const editDeleteButtons = () => {
		if (isCreate) {
			return (
				<button className="baseBtn" onClick={handleSubmit}>
					Crear Patente
				</button>
			);
		}
		//if is update && logged user included as IP
		if (!isCreate || isAdmin) {
			return (
				<>
					<button className="baseBtn" onClick={handleSubmit}>
						Editar Patente
					</button>
					<button
						className="smallBtn btn-danger ml-1"
						onClick={() => deleteIt(PATENTS, id, ROUTES.Patents.all, source)}
					>
						<FontAwesomeIcon icon={faTrash} />
					</button>
				</>
			);
		}
	};

	const selectStaffIds = () => {
		let staffIds: number[] = [];
		chosenStaff.map(({ id }: any) => staffIds.push(id));

		return staffIds;
	};

	// HANDLE STAFF
	const addStaff = (staff: any) => {

		staff = JSON.parse(staff);
		setChosenStaff((state) =>
			produce(state, (drafState) => {
				drafState.push(staff);
			})
		)
		setStaff((state) =>
			produce(state, (drafState) =>
				drafState.filter(({ id }) => id !== staff.id)
			)
		)
	}

	const removeStaff = (staff: any) => {
		//remove staff from list
		setChosenStaff((state) =>
			produce(state, (drafState) =>
				drafState.filter(({ id }) => id !== staff.id)
			)
		)
		// add staff to list
		setStaff((state) =>
			produce(state, (drafState) => {
				drafState.push(staff);
			})
		)

	};

	//----------------

	//----------------
	return (
		<div className="centerPage">
			{(isAdmin || userRoleId === 2) && (
				<>
					{' '}
					<div className="container-fluid ">
						<div className="row">
							<div className="col d-flex justify-content-between">
								<div>
									<h3>Patente</h3>
								</div>
								<div>{editDeleteButtons()}</div>
							</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-12 text-center text-danger">
							<span>{message}</span>
						</div>
					</div>
					<div className="form-row mt-2  d-flex flex-start">
						<div className="col-6 d-flex justify-content-center">
							<FileUploader handleFile={setImage} image={image} />
						</div>
						<div className="col-6  d-flex flex-column  justify-content-center ">
							<small>*Nombre español</small>
							<input
								type="text"
								className="form-control"
								placeholder="Nombre español"
								value={titleEs}
								onChange={({ target: { value } }) => {
									setTitleEs(value);
								}}
								disabled={!allowedEdit}
							/>
							<small className="mt-4">*Nombre inglés</small>
							<input
								type="text"
								className="form-control "
								placeholder="Nombre inglés"
								value={titleEn ? titleEn : ''}
								onChange={({ target: { value } }) => {
									setTitleEn(value);
								}}
								disabled={!allowedEdit}
							/>
							<small className="mt-4">*Nombre valenciano</small>
							<input
								type="text"
								className="form-control "
								placeholder="Nombre valenciano"
								value={titleVa ? titleVa : ''}
								onChange={({ target: { value } }) => {
									setTitleVa(value);
								}}
								disabled={!allowedEdit}
							/>
						</div>
					</div>
					<div className="col-6">
						<small>Seleccionar participantes</small>
						<select
							className="form-control input"
							value={''}
							onChange={({ target: { value } }) => {
								addStaff(value);
							}}
							disabled={!allowedEdit}
						>
							<option value={''}>Participantes</option>
							{staff?.map((i: any) => (
								<option key={i.id} value={JSON.stringify(i)}>
									{`${i.surname1} ${i.surname2 ? i.surname2 : ''}, ${i.name}`}
								</option>
							))}
						</select>
						{chosenStaff?.map((staff: any) => (
							<span
								key={staff.id}
								style={{ cursor: 'pointer' }}
								className="badge badge-info badge-blue-color mr-1 mt-3  "
								onClick={() => removeStaff(staff)}
							>
								{`${staff.surname1} ${staff.surname2 ? staff.surname2 : ''}, ${
									staff.name
								}`}{' '}
								X
							</span>
						))}
					</div>
					{(isCreate || allowedEdit) && (
						<>
							<div className="form-row mt-4">
								<div className="col">
									<small>*Descripción en español</small>
									<CKEditor
										initData={contentEs}
										config={editorConfig}
										onChange={({ editor }: { editor: any }) =>
											setContentEs(editor.getData())
										}
										readOnly={!allowedEdit}
									/>
								</div>
							</div>

							<div className="form-row mt-4">
								<div className="col">
									<small>*Descripción en inglés</small>
									<CKEditor
										initData={contentEn}
										config={editorConfig}
										onChange={({ editor }: { editor: any }) =>
											setContentEn(editor.getData())
										}
										readOnly={!allowedEdit}
									/>
								</div>
							</div>
							<div className="form-row mt-4">
								<div className="col">
									<small>*Descripción en valencianos</small>
									<CKEditor
										initData={contentVa}
										config={editorConfig}
										onChange={({ editor }: { editor: any }) =>
											setContentVa(editor.getData())
										}
										readOnly={!allowedEdit}
									/>
								</div>
							</div>
						</>
					)}
				</>
			)}
		</div>
	);
};

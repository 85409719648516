/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../utils/routes';
import Axios from 'axios';
import { getAll, getById } from '../../../utils/requests/dynamicReq';
import { paths } from '../../../utils/paths';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IStore } from '../../../interfaces/IStore';
import { IUser } from '../../../interfaces/IUser';
import { baseURL } from '../../../utils/constants';

interface ISliderShort {
	id: string;
	image: string;
	textEs: string;
}

export const Sliders: FC = () => {
	const history = useHistory();

	let { staffId, isAdmin, userRoleId } = useSelector(({ user }: IStore) => user as IUser);

	const [isLoading, setIsLoading] = useState(false);

	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();
	const { SLIDERS } = paths;
	const [sliders, setSliders] = useState<any>([]);
	const [search, setSearch] = useState('');
	const [filteredSliders, setFilteredSliders] = useState<ISliderShort[]>([]);

	useEffect(() => {
		setIsLoading(true);
		isMountedRef.current = true;
		try {
			isMountedRef.current && loadSliders();
		} catch (error) {
			console.log(error);
			console.log('HTTP call cancelled');
		}
		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
	}, []);

	const loadSliders = async () => {
		try {
			
				const response = await getAll(SLIDERS, source);
				if (isMountedRef.current) {
					filterSliders(response);
				}		
			setIsLoading(false);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const filterSliders = (rawSliders: any[]) => {
		let sliders: ISliderShort[] = rawSliders.map(
			({ id, image, textEs }: ISliderShort) => ({
				id,
				image,
				textEs
			})
		);
		setSliders(sliders);
		setFilteredSliders(sliders);
	};




	const handleSearch = (value: any) => {
		setSearch(value);
		setFilteredSliders(
			sliders.filter(({ nameEs }: any) =>
				nameEs.toLowerCase().includes(value.toLowerCase())
			)
		);
		!value && setFilteredSliders(sliders);
	};

	return (
		<div className="container-fluid p-4">
			{(isAdmin || userRoleId === 2) && (
				<>
					{' '}
					<div className="row">
						<div className="col-12 d-flex justify-content-between">
							<h3>Imagenes de slider</h3>
						</div>
					</div>
					{/* <div className="center flex-column justify-content-around text-center p-5 mt-2"> */}
					<div className="row mt-4">
						{/* <Form.Group as={Col}>
					<InputGroup className="shadow">
						<InputGroup.Prepend>
							<InputGroup.Text className="inputGroup ">
								<FontAwesomeIcon color="#0073B5" icon={faSearch} />
							</InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							type="text"
							placeholder="Buscar..."
							className="form-control"
							value={search}
							onChange={(e) => handleSearch(e.target.value)}
						/>
					</InputGroup>
				</Form.Group> */}
					</div>
					<div className="row">
						<div className="col-12 ">
							<table className="table table-hover flex-evenly">
								<tbody>
									{filteredSliders?.map(
										({ id, image, textEs }: ISliderShort, index: number) => (
											<tr
												key={index}
												className="pointer flex-center"
												onClick={() =>
													history.push(`${ROUTES.Sliders.all}/${id}`)
												}
											>
												<img
													src={baseURL + image}
													alt=""
													width={300}
													className=""
												/>
												<span className="p-20">{textEs}</span>
											</tr>
										)
									)}
								</tbody>
							</table>
							{isLoading && (
								<div className="d-flex justify-content-center">
									<div className="spinner-border mt-5" role="status" />
								</div>
							)}

							{!filteredSliders.length && !isLoading && isAdmin && (
								<div className="d-flex justify-content-center mb-3">
									<h5>-No existen Sliders-</h5>
								</div>
							)}

							{!filteredSliders.length && !isLoading && !isAdmin && (
								<div className="d-flex justify-content-center mb-3">
									<h5>-No tiene acceso-</h5>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
		// </div>
	);
};

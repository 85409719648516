import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef } from 'react';
import { baseURL } from '../../utils/constants';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import logo from '../../assets/images/pngLogo.png';

interface IProps {
	handleFile(file: any): void;
	cva?: string;
}

export const CvaUploader = ({ handleFile, cva }: IProps) => {
	const inputFile: any = useRef(null);

	const handleChange = (e: any) => {
		const fileUploaded = e.target.files[0];
		if (fileUploaded.size > 15000000) {
			Swal.fire({
				icon: 'error',
				text: 'Pdf demasiado grande, asegúrese de subir una menor a 15mb'
			});
			return;
		}
		handleFile(fileUploaded);
	};

	return (
		<div className="">		
			<input
				type="text"
				style={{ cursor: 'pointer' }}
				className="form-control input"
				placeholder="Suba su CVA"
				onClick={() => inputFile.current?.click()}
			/>
			<input
				type="file"
				ref={inputFile}
				style={{ display: 'none' }}
				id="file"
				accept=".pdf,.doc"
				onChange={handleChange}
			/>
			{!!(typeof cva === 'string') && (
				<a
					href={baseURL + cva}
					download={baseURL + cva}
					target="_blank"
					rel="noreferrer"
				>
					{cva?.split('documents')[1]}
				</a>
			)}
			{!!(cva && typeof cva !== 'string') && (
				<div className="d-flex mt-1">
					<FontAwesomeIcon icon={faCheck} />
					<span className="ml-1">Cva listo para subir</span>
				</div>
			)}
		</div>
	);
};

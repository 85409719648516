export const separateRoles = (investigators) => {
	let ips = investigators
		.filter(({ commissionRole }) => commissionRole === 'coordinator')
		.map(({ staff }) => staff);
	let participants = investigators
		.filter(({ commissionRole }) => commissionRole === 'member')
		.map(({ staff }) => staff);
	return { ips: cleanUsers(ips), participants: cleanUsers(participants) };
};

export const cleanUsers = (users) => {
	let cleanedUsers = [];
	users.map(({ id, name, surname1, surname2 }) =>
		cleanedUsers.push({ id, name, surname1, surname2 })
	);
	return cleanedUsers;
};

export const uniqueUsers = (set1, set2) =>
	[].concat(
		set1?.filter((set) => set2.every(({ id }) => set.id !== id)),
		set2.filter((set) => set1?.every(({ id }) => set.id !== id))
	);

export const buildInvestigatorCommission = (participants, ips, commissionId) => {
	participants = participants.map(({ id }) => ({
		commissionRole: 'member',
		staffId: id,
		commissionId
	}));
	ips = ips.map(({ id }) => ({
		commissionRole: 'coordinator',
		staffId: id,
		commissionId
	}));
	return participants.concat(ips);
};

import Swal from "sweetalert2";
import { SwalError } from "../constants";
import history from "../history";
import { Delete } from "../requests/dynamicReq";

export const useDelete = () => {
  const deleteIt = (path: string, id: number, redirect: string, source: any) => {
    Swal.fire({
      icon: "question",
      text: "¿Seguro quieres eliminarl@?",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "No",
    }).then(async ({ isConfirmed }) => {
      if (isConfirmed) {
        try {
          Delete(path, id, source).then(() => {
            Swal.fire({
              icon: "success",
              text: "Eliminado correctamente",
            });
            history.push(redirect);
          });
        } catch (error) {
          Swal.fire({
            icon: "error",
            text: SwalError,
          });
        }
      }
    });
  };

  return {
    deleteIt,
  };
};

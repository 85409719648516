export const separateRoles = (investigators) => {
	let ips = investigators
		.filter(({ projectRole }) => projectRole === 'IP')
		.map(({ staff }) => staff);
	let participants = investigators
		.filter(({ projectRole }) => projectRole === 'participant')
		.map(({ staff }) => staff);
	return { ips: cleanUsers(ips), participants: cleanUsers(participants) };
};

export const cleanUsers = (users) => {
	let cleanedUsers = [];
	users.map(({ id, name, surname1, surname2, investigationGroup }) =>
		cleanedUsers.push({ id, name, surname1, surname2, investigationGroupId: (investigationGroup?.id || null) })
	);
	return cleanedUsers;
};

export const uniqueUsers = (set1, set2) =>
	[].concat(
		set1?.filter((set) => set2.every(({ staff: { id } }) => set.id !== id)),
		set2.filter((set) => set1?.every(({ id }) => set.staff.id !== id))
	);

export const buildInvestigatorPublication = (participants, ips, projectId) => {
	participants = participants.map(({ id }) => ({
		projectRole: 'PARTICIPANT',
		staffId: id,
		projectId
	}));
	ips = ips.map(({ id }) => ({
		projectRole: 'IP',
		staffId: id,
		projectId
	}));
	return participants.concat(ips);
};

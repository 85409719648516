// prettier-ignore
export const validate = ({ titleEs, titleEn, titleVa, contentEn, contentVa, contentEs, date, commissionId}: any) => {
    let aux = "";
    if (!titleEs) aux = "Por favor rellene el título en Español.";    
    if (!titleEn) aux = 'Por favor rellene el título en Inglès.';  
    if (!titleVa) aux = 'Por favor rellene el título en Valenciano.';  
    if (!contentEs) aux = "Por favor rellene el texto en Español.";
    if (!contentEn) aux = 'Por favor rellene el texto en Inglès.';
    if (!contentVa) aux = 'Por favor rellene el texto en Valenciano.';
    if (!date) aux = "Por favor rellene la fecha"
    if (!commissionId) aux = "Por favor indique a que comisión pertenece el anuncio.";

    return aux;
  };


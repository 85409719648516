/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../utils/routes';
import Axios from 'axios';
import { getAll, getById } from '../../../utils/requests/dynamicReq';
import { paths } from '../../../utils/paths';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IStore } from '../../../interfaces/IStore';
import { IUser } from '../../../interfaces/IUser';

interface IPatentShort {
	id: string;
	titleEs: string;
}

export const Patents: FC = () => {
	const history = useHistory();

	let { staffId, isAdmin, userRoleId } = useSelector(({ user }: IStore) => user as IUser);

	const [isLoading, setIsLoading] = useState(false);

	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();
	const { PATENTS, INVESTIGATORS } = paths;
	const [patents, setPatents] = useState<any>([]);
	const [search, setSearch] = useState('');
	const [filteredPatents, setFilteredPatents] = useState<IPatentShort[]>([]);

	useEffect(() => {
		setIsLoading(true);
		isMountedRef.current = true;
		try {
			isMountedRef.current && loadPatents();
		} catch (error) {
			console.log(error);
			console.log('HTTP call cancelled');
		}
		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
	}, []);

	const loadPatents = async () => {
		try {
			if (isAdmin || userRoleId === 2) {
				const response = await getAll(PATENTS, source);
				if (isMountedRef.current) {
					filterPatents(response);
				}
			} else if (!isAdmin && userRoleId !== 2) {
				let id = staffId.toString();
				const investigator = await getById(INVESTIGATORS, id, source);
				if (isMountedRef.current) {
					filterPatentByInvestigator(investigator);
				}
			}
			setIsLoading(false);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const filterPatents = (rawPatents: any[]) => {
		let patents: IPatentShort[] = rawPatents.map(({ id, titleEs }: IPatentShort) => ({
			id,
			titleEs
		}));
		setPatents(patents);
		setFilteredPatents(patents);
	};

	const filterPatentByInvestigator = (rawPatents: any) => {
		// console.log(rawGroups.investigationGroup);
		if (!!rawPatents.investigationPatent) {
			let patents: IPatentShort[] = [
				{
					id: rawPatents?.investigationPatent?.id,
					titleEs: rawPatents?.investigationPatent?.titleEs
				}
			];
			setPatents(patents);
			setFilteredPatents(patents);
		}
	};

	const handleSearch = (value: any) => {
		setSearch(value);
		setFilteredPatents(
			patents.filter(({ titleEs }: any) =>
				titleEs.toLowerCase().includes(value.toLowerCase())
			)
		);
		!value && setFilteredPatents(patents);
	};

	return (
		<div className="container-fluid p-4">
			{(isAdmin || userRoleId === 2) && (
				<>
					{' '}
					<div className="row">
						<div className="col-12 d-flex justify-content-between">
							<h3>Acciones Transversales</h3>
							<div>
								{isAdmin && (
									<button
										className="baseBtn"
										onClick={() => history.push(`${ROUTES.Patents.create}`)}
									>
										+ Añadir
									</button>
								)}
							</div>
						</div>
					</div>
					{/* <div className="center flex-column justify-content-around text-center p-5 mt-2"> */}
					<div className="row mt-4">
						<Form.Group as={Col}>
							<InputGroup className="shadow">
								<InputGroup.Prepend>
									<InputGroup.Text className="inputGroup ">
										<FontAwesomeIcon color="#0073B5" icon={faSearch} />
									</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									type="text"
									placeholder="Buscar..."
									className="form-control"
									value={search}
									onChange={(e) => handleSearch(e.target.value)}
								/>
							</InputGroup>
						</Form.Group>
					</div>
					<div className="row">
						<div className="col-12 ">
							<table className="table table-hover">
								<tbody>
									{filteredPatents?.map(
										({ id, titleEs }: IPatentShort, index: number) => (
											<tr
												key={index}
												className="pointer"
												onClick={() =>
													history.push(`${ROUTES.Patents.all}/${id}`)
												}
											>
												<td>{`${titleEs}`}</td>
											</tr>
										)
									)}
								</tbody>
							</table>
							{isLoading && (
								<div className="d-flex justify-content-center">
									<div className="spinner-border mt-5" role="status" />
								</div>
							)}

							{!filteredPatents.length && !isLoading && isAdmin && (
								<div className="d-flex justify-content-center mb-3">
									<h5>-No existen grupos-</h5>
								</div>
							)}

							{!filteredPatents.length && !isLoading && !isAdmin && (
								<div className="d-flex justify-content-center mb-3">
									<h5>-No pertenece a ningún grupo-</h5>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
		// </div>
	);
};

import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

//datepicker
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';

//ckeditor
import {CKEditor} from 'ckeditor4-react';
import { editorConfig, SwalError } from '../../../../utils/constants';

//utils
import { FileUploader } from '../../../../components/FileUploader/FileUploader';
import {
	create,
	getAll,
	getById,
	saveFile,
	update
} from '../../../../utils/requests/dynamicReq';
import { paths } from '../../../../utils/paths';


//middleware
import moment from 'moment';

import Swal from 'sweetalert2';
import Axios from 'axios';
import { ROUTES } from '../../../../utils/routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDelete } from '../../../../utils/hooks/useDelete';
import { useSelector } from 'react-redux';
import { validate } from './validations';


registerLocale('es', es);

export const ManageOffer = () => {
	let { isAdmin, userRoleId } = useSelector(({ user }) => user);

	const { OFFERS, INVESTIGATORS, GROUPS } = paths;
	const { id } = useParams();
	const history = useHistory();
	const { deleteIt } = useDelete();

	const [isCreate, setIsCreate] = useState(false);

	const [groups, setGroups] = useState([]);
	const [chosenGroupId, setChosenGroupId] = useState(null);

	//userForm
	const [titleEs, setTitleEs] = useState('');
	const [titleEn, setTitleEn] = useState('');
	const [titleVa, setTitleVa] = useState('');
	
	const [publicationDate, setPublicationDate] = useState(new Date());
	const [limitDate, setLimitDate] = useState(null);
	const [offerType, setOfferType] = useState('');
	
	
	
	const [contentEs, setContentEs] = useState('');
	const [contentEn, setContentEn] = useState('');
	const [contentVa, setContentVa] = useState('');
	
	
	
	const [message, setMessage] = useState('');
		

	const [allowedEdit, setAllowedEdit] = useState(false);

	const isMounted = useRef(false);
	const source = Axios.CancelToken.source();

	useEffect(() => {
		isMounted.current = true;
		const { pathname } = history.location;
		pathname.includes('crear') ? loadDefaultData() : loadEditData();
		return () => {
			isMounted.current = false;
			source.cancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadDefaultData = async () => {
		try {
			let investigators = await getAll(INVESTIGATORS, source);
			let groups = await getAll(GROUPS, source);
			if (isMounted.current) {
				setIsCreate(true);
				setAllowedEdit(true);				
				setGroups(groups);
			}
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};



	const loadEditData = async () => {
		try {
			let response = await getById(OFFERS, id, source);
			isMounted.current && buildObjToLoad(response);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const buildObjToLoad = async (p) => {
		let allGroups = await getAll(GROUPS, source);

		const publicationDateWithOffset = new Date(p.publicationDate);
		const publicationDateNoOffset = new Date(
			publicationDateWithOffset.getTime() -
				publicationDateWithOffset.getTimezoneOffset() * -60000
		);

		if (p.limitDate) // Only initialize limitDate if it comes something from DB (cause its optional).
		{
			const limitDateWithOffset = new Date(p.limitDate);
			const limitDateNoOffset = new Date(limitDateWithOffset.getTime() -limitDateWithOffset.getTimezoneOffset() * -60000);

			setLimitDate(limitDateNoOffset);		
		}
	
		setTitleEs(p.titleEs);
		setTitleEn(p.titleEn);
		setTitleVa(p.titleVa);
		setContentEn(p.contentEn);
		setContentEs(p.contentEs);	
		setContentVa(p.contentVa);			
		setPublicationDate(publicationDateNoOffset);
		setChosenGroupId(p.investigationGroup ? parseInt(p.investigationGroup?.id) : null);		
		setGroups(allGroups);
		setOfferType(p.offerType);

		if (isMounted.current) 
		setAllowedEdit(true);
	};


	

	//----------------------------------------


	const handleSubmit = () => {
		const offer = build();
	 const notValid = validate(offer);
		if (notValid) {
			setMessage(notValid);
			setTimeout(() => setMessage(''), 4000);
			return;
		}
		let question = isCreate ? 'crear un' : 'editar este';
		Swal.fire({
			icon: 'question',
			text: `¿Quieres ${question} proyecto?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(({ isConfirmed }) => isConfirmed && handleConfirmation(offer));
	};

	const handleConfirmation = (offer) => {
		let createOrUpdate = isCreate
			? create(OFFERS, offer, source)
			: update(OFFERS, id, offer, source);
		createOrUpdate
			.then(async ({ id: offerId }) => {					
				let createOrUpdateText = isCreate ? 'creado' : 'editado';

				Swal.fire({
					icon: 'success',
					text: `Proyecto ${createOrUpdateText} correctamente.`
				});			
				history.push(`${ROUTES.Offers.all}/${offerId}`);
				isMounted.current && setIsCreate(false);
			})
			.catch(() => Swal.fire({ icon: 'error', text: SwalError }));
	};

	const build = () => {
		const offer = {
			titleEs,
			titleEn,
			titleVa,
			publicationDate: publicationDate? moment(publicationDate).format('YYYY-MM-DD'): null,
			limitDate: limitDate? moment(limitDate).format('YYYY-MM-DD'): null,
			contentEs,
			contentEn,
			contentVa,
			investigationGroupId: parseInt(chosenGroupId),
			offerType: offerType,
		};

		return offer;
	};

	const editDeleteButtons = () => {
		if (isCreate) {
			return (
				<button className="baseBtn" onClick={handleSubmit}>
					Crear Oferta
				</button>
			);
		}
		//if is update && logged user included as IP

		return (
			<>
				<button className="baseBtn" onClick={handleSubmit}>
					Editar Oferta
				</button>
				<button
					className="smallBtn btn-danger ml-1"
					onClick={() => deleteIt(OFFERS, id, ROUTES.Offers.all, source)}
				>
					<FontAwesomeIcon icon={faTrash} />
				</button>
			</>
		);
	};

	return (
		<div className="centerPage">
			{(userRoleId === 1 || userRoleId === 2 || userRoleId === 3) && (
				<div className="container-fluid p-4">
					<div className="row">
						<div className="col d-flex justify-content-between">
							<div>
								<h3>Oferta </h3>
							</div>
							<div>{editDeleteButtons()}</div>
						</div>
					</div>
					<div className="row mt-4">
						<div className="col-12 text-center text-danger">
							<span>{message}</span>
						</div>
					</div>
					<div className=" form-row mt-2 ">
						<div className="col-6  d-flex flex-column  ">
							<small>*Titulo Español</small>
							<input
								type="text"
								className="form-control"
								placeholder="*Titulo"
								value={titleEs}
								onChange={({ target: { value } }) => setTitleEs(value)}
								disabled={!allowedEdit}
							/>
						</div>
						<div className="col-6  d-flex flex-column  ">
							<small>*Titulo Inglès</small>
							<input
								type="text"
								className="form-control"
								placeholder="*Titulo"
								value={titleEn}
								onChange={({ target: { value } }) => setTitleEn(value)}
								disabled={!allowedEdit}
							/>
						</div>
						<div className="col-6  d-flex flex-column  ">
							<small>*Titulo Valenciano</small>
							<input
								type="text"
								className="form-control"
								placeholder="*Titulo"
								value={titleVa}
								onChange={({ target: { value } }) => setTitleVa(value)}
								disabled={!allowedEdit}
							/>
						</div>
					</div>

					<div className="form-row mt-2">
						<div className="col">
							<small>*Fecha de publicación</small>
							<DatePicker
								className="form-control input"
								placeholderText="*Inicio"
								selected={publicationDate}
								onChange={(date) => setPublicationDate(date)}
								dateFormat="dd MMMM, yyyy"
								isClearable={allowedEdit}
								locale="es"
								disabled={!allowedEdit}
							/>
						</div>
						<div className="col">
							<small>Fecha límite</small>
							<DatePicker
								className="form-control input"
								placeholderText="Finalización"
								selected={limitDate}
								onChange={(date) => setLimitDate(date)}
								dateFormat="dd MMMM, yyyy"
								isClearable={allowedEdit}
								locale="es"
								disabled={!allowedEdit}
							/>
						</div>
					</div>

					<div className="form-row mt-4">
						<div className="col-6">
							<small>Seleccionar Grupo</small>
							<select
								className="form-control input"
								value={chosenGroupId}
								onChange={({ target: { value } }) => {
									setChosenGroupId(value);
								}}
							>
								<option value={null}>No procede</option>
								{groups?.map(({ id, nameEs }) => (
									<option key={id} value={id}>
										{nameEs}
									</option>
								))}
							</select>
						</div>
						<div className="col-6">
							<small>*Tipo de oferta</small>
							<select
								className="form-control input"
								value={offerType}
								onChange={({ target: { value } }) => {
									setOfferType(value);
								}}
							>
								<option value="">Tipo</option>
								<option value="job">Trabajo</option>
								<option value="expression_of_interest">
									Expresion de interes
								</option>
							</select>
						</div>
					</div>

					{(isCreate || allowedEdit) && (
						<>
							<div className="form-row mt-4">
								<div className="col">
									<small>*Texto en español</small>
									<CKEditor
										initData={contentEs}
										config={editorConfig}
										onChange={({ editor }) => setContentEs(editor.getData())}
										readOnly={!allowedEdit}
									/>
								</div>
							</div>

							<div className="form-row mt-4">
								<div className="col">
									<small>*Texto en inglés</small>
									<CKEditor
										initData={contentEn}
										config={editorConfig}
										onChange={({ editor }) => setContentEn(editor.getData())}
										readOnly={!allowedEdit}
									/>
								</div>
							</div>
							<div className="form-row mt-4">
								<div className="col">
									<small>*Texto en valenciano</small>
									<CKEditor
										initData={contentVa}
										config={editorConfig}
										onChange={({ editor }) => setContentVa(editor.getData())}
										readOnly={!allowedEdit}
									/>
								</div>
							</div>
						</>
					)}
				</div>
			)}
		</div>
	);
};

/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

//search feature
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Col from "react-bootstrap/esm/Col";
import Form from "react-bootstrap/esm/Form";
import InputGroup from "react-bootstrap/esm/InputGroup";
import { ROUTES } from "../../../utils/routes";
import Axios from "axios";
import { getAll, getByInvestigator } from "../../../utils/requests/dynamicReq";
import { paths } from "../../../utils/paths";
import { IProject } from "../../../interfaces/IProject";
import { useSelector } from "react-redux";
import { IStore } from "../../../interfaces/IStore";
import { IUser } from "../../../interfaces/IUser";
import { investigatorRoles } from "../../../utils/roles";
import { normalizer } from "../../../utils/auxiliar/investigators";

export const Projects: FC = () => {
  let { staffId, isAdmin, userRoleId } = useSelector(
    ({ user }: IStore) => user as IUser
  );
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const isMountedRef = useRef(false);
  const source = Axios.CancelToken.source();
  const { PROJECTS } = paths;
  const [projects, setProjects] = useState<IProject[]>([]);
  const [displayProjects, setDisplayProjects] = useState<IProject[]>([]);

  useEffect(() => {
    setIsLoading(true);
    isMountedRef.current = true;
    loadData();
    return () => {
      isMountedRef.current = false;
      source.cancel();
    };
  }, []);

  const loadData = async () => {
    try {
      const response = isAdmin || userRoleId === 2 || userRoleId === 3
        ? await getAll(PROJECTS, source)
        : await getByInvestigator(
            `${PROJECTS}/investigator`,
            staffId,
            source
          );
      if (isMountedRef.current) {
        setProjects(response);
       
        setDisplayProjects(response);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("HTTP call cancelled"); 
    }
  };

  const searchBar = (value: any) => {
    setDisplayProjects(
      projects.filter(({ nameEs }: any) =>
        normalizer(nameEs).toLowerCase().includes(normalizer(value).toLowerCase())
      )
    );
    !value && setDisplayProjects(projects);
  };

  return (
		<div className="container-fluid p-4">
			{(isAdmin || userRoleId === 2 || userRoleId === 3) && (
				<>
					{' '}
					<div className="row">
						<div className="col d-flex justify-content-between">
							<div>
								<h3>Proyectos</h3>
							</div>
							<div>
								<button
									className="baseBtn"
									onClick={() => history.push(ROUTES.Projects.create)}
								>
									+ Añadir
								</button>
							</div>
						</div>
					</div>
					<div className="row mt-4">
						<Form.Group as={Col}>
							<InputGroup className="shadow">
								<InputGroup.Prepend>
									<InputGroup.Text className="inputGroup ">
										<FontAwesomeIcon color="#0073B5" icon={faSearch} />
									</InputGroup.Text>
								</InputGroup.Prepend>
								<Form.Control
									type="text"
									placeholder="Buscar..."
									className="form-control"
									onChange={({ target: { value } }) => searchBar(value)}
								/>
							</InputGroup>
						</Form.Group>
					</div>
					<div className="row">
						<div className="col-12 ">
							<table className="table table-hover">
								<tbody>
									{displayProjects?.map(({ id, nameEs }) => (
										<tr
											key={id}
											className="pointer"
											onClick={() =>
												history.push(`${ROUTES.Projects.all}/${id}`)
											}
										>
											<td>{nameEs}</td>
										</tr>
									))}
								</tbody>
							</table>
							{isLoading && (
								<div className="d-flex justify-content-center">
									<div className="spinner-border mt-5" role="status" />
								</div>
							)}

							{!displayProjects.length && !isLoading && (
								<div className="d-flex justify-content-center mb-3">
									<h5>-No hay proyectos para mostrar-</h5>
								</div>
							)}
						</div>
					</div>
				</>
			)}
		</div>
	);
};

import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FileUploader } from '../../../../components/FileUploader/FileUploader';
import Axios from 'axios';
import moment from 'moment';
import { CKEditor } from 'ckeditor4-react';
import { editorConfig, SwalError } from '../../../../utils/constants';
import {
	create,
	getAll,
	getById,
	saveFile,
	update
} from '../../../../utils/requests/dynamicReq';
import { ROUTES } from '../../../../utils/routes';
import Swal from 'sweetalert2';
import { paths } from '../../../../utils/paths';
import { useDelete } from '../../../../utils/hooks/useDelete';
import { validate } from './validations';

export const ManageAnnouncements = () => {
	const { ANNOUNCEMENTS, COMMISSIONS, PROJECTS, INVESTIGATORS } = paths;
	const { id } = useParams();
	const { deleteIt } = useDelete();

	let { staffId, isAdmin, userId } = useSelector(({ user }) => user);

	const history = useHistory();

	const [isCreate, setIsCreate] = useState(false);
	const [url, setUrl] = useState('');

	const [titleEs, setTitleEs] = useState('');
	const [titleEn, setTitleEn] = useState('');
	const [titleVa, setTitleVa] = useState('');
	const [contentEs, setContentEs] = useState('');
	const [contentEn, setContentEn] = useState('');
	const [contentVa, setContentVa] = useState('');
	const [keywords, setKeywords] = useState('');
	const [commissions, setCommissions] = useState([]);
	const [chosenCommissionId, setChosenCommissionId] = useState('');

	const [creatorId, setCreatorId] = useState(null);
	const [date, setDate] = useState(new Date());
	const [image, setImage] = useState();
	const [message, setMessage] = useState('');
	const [allowedEdit, setAllowedEdit] = useState(false);

	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();

	useEffect(() => {
		isMountedRef.current = true;
		const { pathname } = history.location;
		pathname.includes('crear') ? setIsCreate(true) : loadData();
		loadStaticData();
		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadStaticData = async () => {
		try {
			let commissions = isAdmin
				? await getAll(COMMISSIONS, source)
				: await getById(INVESTIGATORS, staffId, source);
			isMountedRef.current && isAdmin
				? setCommissions(commissions)
				: setCommissions(commissions.staffCommissions);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const loadData = async () => {
		try {
			let response = await getById(ANNOUNCEMENTS, id, source);
			isMountedRef.current && buildObjToLoad(response);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const buildObjToLoad = async (response) => {
		let allCommissions = isAdmin
			? await getAll(COMMISSIONS, source)
			: await getById(INVESTIGATORS, staffId, source);
		const {
			titleEn,
			titleEs,
			titleVa,
			contentVa,
			contentEn,
			contentEs,
			url,
			date
		} = response;
		const {
			user: { id: userId }
		} = response;
		const dateWithOffset = new Date(date);
		const dateNoOffset = new Date(
			dateWithOffset.getTime() - dateWithOffset.getTimezoneOffset() * -60000
		);

		setTitleEn(titleEn);
		setTitleEs(titleEs);
		setTitleVa(titleVa);
		setUrl(url);
		setCreatorId(userId);
		setDate(dateNoOffset);
		setContentEs(contentEs);
		setContentEn(contentEn);
		setContentVa(contentVa);
		isAdmin
			? setCommissions(allCommissions)
			: setCommissions(allCommissions.staffCommissions);
		setChosenCommissionId(response.commission?.id);
		if (isMountedRef.current) setAllowedEdit(true);
	};

	const handleSubmit = () => {
		const announcement = build();
		const notValid = validate(announcement);
		if (notValid) {
			setMessage(notValid);
			setTimeout(() => setMessage(''), 4000);
			return;
		}
		let question = isCreate ? 'crear un' : 'editar este';
		Swal.fire({
			icon: 'question',
			text: `¿Quieres ${question} anuncio?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(({ isConfirmed }) => {
			isConfirmed && handleConfirmation(announcement);
		});
	};

	const handleConfirmation = (announcement) => {
		let createOrUpdate = isCreate
			? create(ANNOUNCEMENTS, announcement, source)
			: update(ANNOUNCEMENTS, id, announcement, source);
		createOrUpdate
			.then(async ({ id }) => {
				Swal.fire({
					icon: 'success',
					text: `Anuncio ${isCreate ? 'creado' : 'editado'} correctamente.`
				});
				history.push(`${ROUTES.Announcements.all}`);
				setIsCreate(false);
			})
			.catch(() => {
				Swal.fire({
					icon: 'error',
					text: SwalError
				});
			});
	};

	const build = () => ({
		userId: creatorId ? creatorId : userId,
		titleEs,
		titleEn,
		titleVa,
		date: moment(date).format('YYYY-MM-DD'),
		url,
		contentEs,
		contentEn,
		contentVa,
		keywords,
		commissionId: +chosenCommissionId
	});

	const exists = isAdmin
		? commissions.some((c) => c.id === chosenCommissionId)
		: commissions.some((c) => c.commission.id === chosenCommissionId);

const editDeleteButtons = () => {
  const isMemberOfCommission = commissions.some(
    (c) => c.commission?.id === chosenCommissionId
  );

  if (isCreate) {
    return (
      <button className="baseBtn" onClick={handleSubmit}>
        Crear Anuncio
      </button>
    );
  }

  if (!isCreate && (userId === creatorId || isAdmin || isMemberOfCommission)) {
    return (
      <>
        <button className="baseBtn" onClick={handleSubmit}>
          Editar Anuncio
        </button>
        <button
          className="smallBtn btn-danger ml-1"
          onClick={() =>
            deleteIt(ANNOUNCEMENTS, id, ROUTES.Announcements.all, source)
          }
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </>
    );
  }
  return null;
};


	return (
		<div className="centerPage">
			<div className="container-fluid p-4">
				<div className="row">
					<div className="col d-flex justify-content-between">
						<div>
							<h3>Anuncio de comisión </h3>
						</div>
						{isAdmin || exists ? <div>{editDeleteButtons()}</div> : <></>}
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center text-danger">
						<span>{message}</span>
					</div>
				</div>
				<div className="form-row mt-2">
					<div className="col-6 d-flex flex-column justify-content-center">
						<small>Enlace</small>
						<input
							type="text"
							className="form-control"
							placeholder={url}
							value={url}
							onChange={({ target: { value } }) => {
								setUrl(value);
							}}
						/>
					</div>
				</div>
				<div className="form-row mt-2">
					<div className="col">
						<small>*Título español</small>
						<input
							type="text"
							className="form-control"
							placeholder="*Título español"
							value={titleEs}
							onChange={({ target: { value } }) => {
								setTitleEs(value);
							}}
						/>
					</div>
					<div className="col">
						<small>*Título inglés</small>
						<input
							type="text"
							className="form-control"
							placeholder="Título inglés"
							value={titleEn}
							onChange={({ target: { value } }) => {
								setTitleEn(value);
							}}
						/>
					</div>
					<div className="col">
						<small>*Título valenciano</small>
						<input
							type="text"
							className="form-control"
							placeholder="Título inglés"
							value={titleVa}
							onChange={({ target: { value } }) => {
								setTitleVa(value);
							}}
						/>
					</div>
				</div>
				<div className="form-row mt-2">
					<div className="col-6">
						{isAdmin || exists || isCreate ? (
							<>
								<small>*Seleccionar comisión</small>
								<select
									className="form-control input"
									value={chosenCommissionId}
									onChange={({ target: { value } }) => {
										setChosenCommissionId(Number(value));
									}}
								>
									<option value="">Seleccionar Comisión</option>
									{isAdmin
										? commissions?.map((c) => (
												<option key={c.id} value={c.id}>
													{c.nameEs}
												</option>
										  ))
										: commissions?.map((c) => (
												<option key={c.commission?.id} value={c.commission?.id}>
													{c.commission?.nameEs}
												</option>
										  ))}
								</select>
							</>
						) : (
							<></>
						)}

						<small>*Fecha de publicación</small>
						<DatePicker
							className="form-control input"
							placeholderText="*Fecha"
							selected={date}
							onChange={(date) => setDate(date)}
							dateFormat="dd MMMM, yyyy"
							isClearable
							locale="es"
							dropdownMode="select"
							showMonthDropdown
							showYearDropdown
							adjustDateOnChange
						/>
					</div>
				</div>
				{(isCreate || allowedEdit) && (
					<>
						<div className="form-row mt-4">
							<div className="col">
								<small>*Texto en español</small>
								<CKEditor
									initData={contentEs}
									config={editorConfig}
									onChange={({ editor }) => {
										setContentEs(editor.getData());
									}}
								/>
							</div>
						</div>
						<div className="form-row mt-4">
							<div className="col">
								<small>*Texto en inglés</small>
								<CKEditor
									initData={contentEn}
									config={editorConfig}
									onChange={({ editor }) => {
										setContentEn(editor.getData());
									}}
								/>
							</div>
						</div>
						<div className="form-row mt-4">
							<div className="col">
								<small>*Texto en valenciano</small>
								<CKEditor
									initData={contentVa}
									config={editorConfig}
									onChange={({ editor }) => {
										setContentVa(editor.getData());
									}}
								/>
							</div>
						</div>
					</>
				)}
			</div>
		</div>
	);
};

import { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// datepicker

import { registerLocale } from 'react-datepicker';
import es from 'date-fns/locale/es';
import 'react-datepicker/dist/react-datepicker.css';
// ckeditor

import { SwalError } from '../../../../utils/constants';

// utils

import {
	create,
	getAll,
	getById,
	update
} from '../../../../utils/requests/dynamicReq';
import { paths } from '../../../../utils/paths';
import {
	cleanUsers,
	uniqueUsers,
} from '../../../../utils/auxiliar/managePublications';

// middleware

import produce from 'immer';
import Swal from 'sweetalert2';
import Axios from 'axios';

import { ROUTES } from '../../../../utils/routes';

import { useDelete } from '../../../../utils/hooks/useDelete';
import { useSelector } from 'react-redux';

registerLocale('es', es);

export const ManagePublications = () => {
	let { investigatorId, isAdmin, token } = useSelector(({ user }) => user);

	const { PUBLICATIONS, INVESTIGATORS, investigatorPUBLICATION, groupPUBLICATION } = paths;
	const { id } = useParams();
	const history = useHistory();
	const { deleteIt } = useDelete();

	const [isCreate, setIsCreate] = useState(false);
	const [message, setMessage] = useState('');
	// userForm
	const [title, setTitle] = useState('');

	const [participants, setParticipants] = useState([]);
	const [chosenParticipants, setChosenParticipants] = useState([]);

	const [staffAssociated, setStaffAssociated] = useState([]);
	const [investigationGroupsAssociated, setInvestigationGroupsAssociated] = useState([]);


	const [allowedEdit, setAllowedEdit] = useState(false);

	const isMounted = useRef(false);
	const source = Axios.CancelToken.source();

	useEffect(() => {
		isMounted.current = true;
		const { pathname } = history.location;
		pathname.includes('crear') ? loadDefaultData() : loadEditData();
		return () => {
			isMounted.current = false;
			source.cancel();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loadDefaultData = async () => {
		try {
			let investigators = await getAll(INVESTIGATORS, source);
			if (isMounted.current) {
				setIsCreate(true);
				setAllowedEdit(true);
				setTimeout(() => filterAdmins(investigators), 250);
			}

		} catch (error) {
			console.log('HTTP call cancelado');
		}
	};



	const filterAdmins = (investigators) => {
		let newinvestigators = investigators.filter(
			(u) =>
				u.email !== 'admin@admin.com' &&
				u.email !== 'sombradoble@sombradoble.es'
		);
		setParticipants(newinvestigators);
	};

	const loadEditData = async () => {
		try {
			let response = await getById(PUBLICATIONS, id, source);

			isMounted.current && buildObjToLoad(response);
		} catch (error) {
			console.log('HTTP call cancelado');
		}
	};

	const buildObjToLoad = async (p) => {
		setTitle(p.title);
		setStaffAssociated(p.staffAssociated)
		setInvestigationGroupsAssociated(p.investigationGroupsAssociated)
		let investigators = await getAll(INVESTIGATORS, source);
		if (isMounted.current) {
			setParticipantsDisplay(p, investigators);
			setAllowedEdit(true);
		}
	};

	const setParticipantsDisplay = async (
		{ staffPublications },
		allStaff
	) => {
		setChosenParticipants(staffPublications);
		// get unassigned investigators by comparing with participants
		let nonChosenStaff = uniqueUsers(allStaff, staffPublications);
		setParticipants(cleanUsers(nonChosenStaff));

	};

	//----------------------------------------



	// HANDLE INVESTIGATORS
	const addInvestigator = (ip) => {
		const parsedIp = JSON.parse(ip);

		const newParticipant = {
			staff: {
				id: parsedIp.id,
				name: parsedIp.name,
				surname1: parsedIp.surname1,
				surname2: parsedIp.surname2,
				investigationGroup: { id: parsedIp.investigationGroupId }
			}
		};

		// add chosenParticipant
		setChosenParticipants((state) =>
			produce(state, (draftState) => {
				draftState.push(newParticipant);
			})
		);

		// remove ip from list
		setParticipants((state) =>
			produce(state, (draftState) =>
				draftState.filter(({ id }) => id !== parsedIp.id)
			)
		);
	};

	const removeInvestigator = (participant) => {
		if (!allowedEdit) return;

		// Remove chosenParticipant
		setChosenParticipants((state) =>
			produce(state, (draftState) =>
				draftState.filter(({ staff }) => staff.id !== participant.staff.id)
			)
		);

		// Add participant back to list
		setParticipants((state) =>
			produce(state, (draftState) => {
				draftState.push(participant.staff);
			})
		);


	};

	const handleSubmit = () => {
		const publication = build();
		let question = isCreate ? 'crear una' : 'editar esta';
		Swal.fire({
			icon: 'question',
			text: `¿Quieres ${question} publicación?`,
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(
			({ isConfirmed }) => isConfirmed && handleConfirmation(publication)
		);
	};

	const handleConfirmation = (publication) => {
		let createOrUpdate = isCreate
			? create(PUBLICATIONS, publication, source)
			: update(PUBLICATIONS, id, publication, source);
		createOrUpdate
			.then(async ({ id: publicationId }) => {

				//Armo array para staff-publication
				const staffPublications = chosenParticipants.map(
					(participant) => ({
						staffId: participant.staff.id,
						publicationId: publicationId,
					})
				);
				// Le agregamos los selected desde BD
				const staffPublicationsWithSelected = staffPublications.map(choosenStaff => {
					const match = staffAssociated.find(staffFromDB => staffFromDB.staffId === choosenStaff.staffId);
					return {
						...choosenStaff,
						selected: match ? match.selected : false
					};
				});

				//Armo array para investigationGroup-publication
				const seenIds = new Set();
				const investigationGroupPublications = chosenParticipants
					.filter(participant => participant.staff.investigationGroup?.id)
					.filter(participant => {
						if (seenIds.has(participant.staff.investigationGroup.id)) {
							return false;
						} else {
							seenIds.add(participant.staff.investigationGroup.id);
							return true;
						}
					})
					.map(participant => ({
						investigationGroupId: participant.staff.investigationGroup.id,
						publicationId: publicationId,
					}));

				// Le agregamos los selected desde BD
				const investigationGroupPublicationsWithSelected = investigationGroupPublications.map(choosenGroups => {
					const match = investigationGroupsAssociated.find(groupFromDB => groupFromDB.investigationGroupId === choosenGroups.investigationGroupId);
					return {
						...choosenGroups,
						selected: match ? match.selected : false
					};
				});

				//API CALL TO CREATE BOTH GROUP-PUBLICATION AND STAFF-PUBLICATION
				await create(
					`${groupPUBLICATION}/${publicationId}`,
					investigationGroupPublicationsWithSelected,
					source
				);
				await create(
					`${investigatorPUBLICATION}/${publicationId}`,
					staffPublicationsWithSelected,
					source
				);
				let createOrUpdateText = isCreate ? 'creada' : 'editada';

				Swal.fire({
					icon: 'success',
					text: `Publicacion ${createOrUpdateText} correctamente.`
				});
				history.push(`${ROUTES.Publications.all}/${publicationId}`);
				isMounted.current && setIsCreate(false);
			})
			.catch(() => Swal.fire({ icon: 'error', text: SwalError }));
	};

	const build = () => {
		const publication = {
			chosenParticipants
		};
		return publication;
	};

	const editDeleteButtons = () => {
		return (
			<>
				<button className="baseBtn" onClick={handleSubmit}>
					Editar Publicacion
				</button>
			</>
		);
	};

	const excludePublication = async (e) => {
		Swal.fire({
			title: 'Excluir',
			text: 'Esta seguro que desea excluir esta publicación? Esta acción no puede deshacerse',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: 'Si',
			cancelButtonText: 'No'
		}).then(async ({ isConfirmed }) => {
			if (isConfirmed) {
				try {
					update(
						PUBLICATIONS,
						e.target.value,
						{ status: 'excluded' },
						token,
						source
					).then(() => {
						Swal.fire({
							text: 'Se ha excluido la publicación',
							icon: 'success'
						});
						history.push('/publicaciones');

					});
				} catch (error) {
					Swal.fire({
						icon: 'error',
						text: SwalError
					});
				}
			} else {
				Swal.fire({ text: 'No se ha excluido la publicación', icon: 'info' });
			}
		});
	};

	return (
		<div className="centerPage">
			<div className="container-fluid p-4">
				<div className="row">
					<div className="col d-flex justify-content-between">
						<div>
							<h3>{title} </h3>
						</div>
						<div>{editDeleteButtons()}</div>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-12 text-center text-danger">
						<span>{message}</span>
					</div>
				</div>
				<div className="form-row mt-4">
					<div className="col">
						<select
							className="form-control input"
							value={''}
							onChange={({ target: { value } }) => addInvestigator(value)}
							disabled={!allowedEdit}
						>
							<option value={''}>Investigadores participantes</option>
							{participants?.map((i) => (
								<option key={i.id} value={JSON.stringify(i)}>
									{`${i.surname1} ${i.surname2 ? i.surname2 : ''}, ${i.name}`}
								</option>
							))}
						</select>
						{chosenParticipants?.map((participant) => (
							<span
								key={participant?.staff?.id}
								style={{ cursor: 'pointer' }}
								className="badge badge-info badge-blue-color mr-1 mt-3"
								onClick={() => removeInvestigator(participant)}
							>
								{`${participant?.staff.name} ${participant?.staff.surname1} ${participant?.staff.surname2 ? participant?.staff.surname2 : ''
									}`}
								X
							</span>
						))}
					</div>
				</div>
			</div>
			<button
				value={id}
				onClick={excludePublication}
				className="redBtn"
			>
				Excluir publicación
			</button>
		</div>
	);
};

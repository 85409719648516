/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '../../../utils/routes';
import Axios from 'axios';
import { getAll, getById } from '../../../utils/requests/dynamicReq';
import { paths } from '../../../utils/paths';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { IStore } from '../../../interfaces/IStore';
import { IUser } from '../../../interfaces/IUser';

interface IOfferShort {
	id: string;
	titleEs: string;
}

export const Offers: FC = () => {
	const history = useHistory();

	let { staffId, isAdmin, userRoleId } = useSelector(({ user }: IStore) => user as IUser);

	const [isLoading, setIsLoading] = useState(false);

	const isMountedRef = useRef(false);
	const source = Axios.CancelToken.source();
	const { OFFERS, INVESTIGATORS } = paths;
	const [offers, setOffers] = useState<any>([]);
	const [search, setSearch] = useState('');
	const [filteredOffers, setFilteredOffers] = useState<IOfferShort[]>([]);
	console.log(offers, 'offers');
	console.log(filteredOffers, 'filtered');
	
	

	useEffect(() => {
		setIsLoading(true);
		isMountedRef.current = true;
		console.log('usef');
		
		try {
			isMountedRef.current && loadOffers();
		} catch (error) {
			console.log(error);
			console.log('HTTP call cancelled');
		}
		return () => {
			isMountedRef.current = false;
			source.cancel();
		};
	}, []);

	const loadOffers = async () => {
		try {
			if (isAdmin || userRoleId === 2 || userRoleId === 3 ) {
				const response = await getAll(OFFERS, source);
				if (isMountedRef.current) {
					filterOffers(response);
				}
			} else if (userRoleId !== 1) {
				let id = staffId.toString();
				const investigator = await getById(INVESTIGATORS, id, source);
				if (isMountedRef.current) {
					filterOfferByInvestigator(investigator);
				}
			}
			setIsLoading(false);
		} catch (error) {
			console.log('HTTP call cancelled');
		}
	};

	const filterOffers = (rawOffers: any[]) => {
		let offers: IOfferShort[] = rawOffers.map(({ id, titleEs }: IOfferShort) => ({
			id,
			titleEs
		}));
		setOffers(offers);
		setFilteredOffers(offers);
	};

	const filterOfferByInvestigator = (rawOffers: any) => {
		// console.log(rawGroups.investigationGroup);
		if (!!rawOffers.investigationOffer) {
			let offers: IOfferShort[] = [
				{
					id: rawOffers?.investigationOffer?.id,
					titleEs: rawOffers?.investigationOffer?.titleEs
				}
			];
			setOffers(offers);
			setFilteredOffers(offers);
		}
	};

	const handleSearch = (value: any) => {
		setSearch(value);
		setFilteredOffers(
			offers.filter(({ title }: any) =>
				title.toLowerCase().includes(value.toLowerCase())
			)
		);
		!value && setFilteredOffers(offers);
	};

	return (
		<div className="container-fluid p-4">
			{(isAdmin || userRoleId === 2 || userRoleId === 3) && (
			<>
			<div className="row">
				<div className="col-12 d-flex justify-content-between">
					<h3>Ofertas</h3>
					<div>
						{userRoleId === 1 && (
							<button
								className="baseBtn"
								onClick={() => history.push(`${ROUTES.Offers.create}`)}
							>
								+ Añadir
							</button>
						)}
					</div>
				</div>
			</div>
			{/* <div className="center flex-column justify-content-around text-center p-5 mt-2"> */}
			<div className="row mt-4">
				<Form.Group as={Col}>
					<InputGroup className="shadow">
						<InputGroup.Prepend>
							<InputGroup.Text className="inputGroup ">
								<FontAwesomeIcon color="#0073B5" icon={faSearch} />
							</InputGroup.Text>
						</InputGroup.Prepend>
						<Form.Control
							type="text"
							placeholder="Buscar..."
							className="form-control"
							value={search}
							onChange={(e) => handleSearch(e.target.value)}
						/>
					</InputGroup>
				</Form.Group>
			</div>
			<div className="row">
				<div className="col-12 ">
					<table className="table table-hover">
						<tbody>
							{filteredOffers?.map(
								({ id, titleEs }: IOfferShort, index: number) => (
									<tr
										key={index}
										className="pointer"
										onClick={() => history.push(`${ROUTES.Offers.all}/${id}`)}
									>
										<td>{`${titleEs}`}</td>
									</tr>
								)
							)}
						</tbody>
					</table>
					{isLoading && (
						<div className="d-flex justify-content-center">
							<div className="spinner-border mt-5" role="status" />
						</div>
					)}

					{!filteredOffers.length && !isLoading && userRoleId === 1 && (
						<div className="d-flex justify-content-center mb-3">
							<h5>-No existen grupos-</h5>
						</div>
					)}

					{!filteredOffers.length && !isLoading && userRoleId !== 1 && (
						<div className="d-flex justify-content-center mb-3">
							<h5>-No pertenece a ningún grupo-</h5>
						</div>
					)}
				</div>
			</div></>)}
			
		</div>
		// </div>
	);
};
